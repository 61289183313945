import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './risk-profile.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronBack from 'app/component/icon/chevron-back';
import Toggle from 'react-toggle';
import axios from 'axios';
import { services } from 'app/util/const';
import Select from 'react-select';
import { ButtonComponent } from 'app/component';
import { colours } from 'app/config/constant/colours';
import _, { iteratee } from 'lodash';
import { toast } from 'react-toastify';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';

export const RiskProfile = props => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const [listQuestion, setListQuestion] = useState<any>([]);
  const [listCategories, setListCategories] = useState<any>([]);
  const [profileRisk, setProfileRisk] = useState<any>(null);
  const [loadingRiskProfile, setLoadingRiskProfile] = useState<any>(false);

  const goBack = () => {
    navigate(-1);
  };

  const getListQuestion = () => {
    axios
      .get(services.api.riskprofile.list)
      .then(res => {
        let data = [];
        res.data.data.map(item => {
          data.push({ ...item, riskQuestionsId: item.id, riskQuestionChoicesId: item.answer.id });
        });
        setListQuestion(data);
      })
      .catch(err => {
        setListQuestion([]);
      });
  };

  const getListCategories = () => {
    axios
      .get(services.api.riskprofile.categories)
      .then(res => {
        setListCategories(res.data.data);
      })
      .catch(err => {
        setListCategories([]);
      });
  };

  const saveProfileRisk = () => {
    setLoadingRiskProfile(true);
    let form = [];
    listQuestion.map((item, index) => {
      form.push({
        riskQuestionsId: item.riskQuestionsId,
        riskQuestionChoicesId: item.riskQuestionChoicesId,
      });
    });

    axios
      .post(services.api.riskprofile.save, form)
      .then(res => {
        console.log(res);
        setLoadingRiskProfile(false);
        toast.success('Successfully Change Risk Profile');
        dispatch(getSession());
        dispatch(getProfile());
      })
      .catch(err => {
        console.log(err);
        setLoadingRiskProfile(false);
      });
  };

  useEffect(() => {
    getListQuestion();
    getListCategories();
  }, []);

  useEffect(() => {
    getScore();
  }, [listQuestion]);

  const getScore = () => {
    setLoadingRiskProfile(true);
    let score = 0;

    listQuestion.map(item => {
      score = score + item.answer.score;
    });

    let riskprofile = listCategories.filter((data, index) => {
      if (score >= data.scoreFrom && score <= data.scoreTo) {
        return data;
      }
    });

    setProfileRisk(riskprofile[0]);
    setLoadingRiskProfile(false);
  };

  const onChangeAnswer = (question: any, answer: any) => {
    let questionList = listQuestion;
    console.log('contoh', questionList[questionList.findIndex(x => x.id == question.id)].riskQuestionChoices);
    questionList[questionList.findIndex(x => x.id == question.id)] = {
      ...questionList[questionList.findIndex(x => x.id == question.id)],
      answer: questionList[questionList.findIndex(x => x.id == question.id)].riskQuestionChoices.filter(x => x.id == answer.id)[0],
      riskQuestionChoicesId: answer.id,
      riskQuestionsId: question.id,
    };
    setListQuestion(_.cloneDeep(questionList));
    console.log(listQuestion);
  };

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />

      <div className="container mt-5 mb-5">
        <div
          style={{ display: 'flex', alignContent: 'center', alignItems: 'center', alignSelf: 'center', cursor: 'pointer' }}
          onClick={() => goBack()}
        >
          <ChevronBack />
          <h5 style={{ color: '#0A0A0A', fontWeight: 'normal', fontSize: 18, marginBottom: 0 }} className="mx-2">
            Risk Profile
          </h5>
        </div>
        {listQuestion.length > 0 &&
          listQuestion.map((item, index) => {
            return (
              <div className="my-4" key={index}>
                <Label for="examplePassword">
                  {`${index + 1}. `}
                  {item.question}
                </Label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={item.riskQuestionChoices}
                  onChange={e => {
                    onChangeAnswer(item, e);
                  }}
                  isDisabled={loadingRiskProfile}
                  getOptionValue={option => `${option['id']}`}
                  getOptionLabel={option => `${option['choice']}`}
                  value={item.riskQuestionChoices.filter(function (option: any) {
                    return option.id == item.riskQuestionChoicesId;
                  })}
                />
              </div>
            );
          })}

        <div className="row">
          <div className="col-12 col-md-3 col-lg-3">
            <ButtonComponent
              text={'Calculate Risk Profile'}
              onPress={() => getScore()}
              wrapperClass={''}
              outline={true}
              disabled={loadingRiskProfile}
              buttonStyle={{
                backgroundColor: colours.primaryOrange,
                borderColor: colours.primaryOrange,
                borderRadius: 0,
              }}
              textStyle={{
                color: colours.white,
              }}
            />
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <ButtonComponent
              text={'Simpan'}
              onPress={() => saveProfileRisk()}
              wrapperClass={''}
              outline={true}
              disabled={loadingRiskProfile}
              buttonStyle={{
                backgroundColor: colours.primaryOrange,
                borderColor: colours.primaryOrange,
                borderRadius: 0,
              }}
              textStyle={{
                color: colours.white,
              }}
            />
          </div>
        </div>
        <div className="mt-4 mx-3">
          <h5 style={{ color: '#404040', fontSize: 16, fontWeight: 500 }}>{profileRisk?.name}</h5>
          <p style={{ color: '#616161', fontSize: 16, fontWeight: 400 }}>{profileRisk?.description}</p>
        </div>
        <hr className="mt-5" />

        <div className="container mt-5 mb-5">
          <div
            style={{ display: 'flex', alignContent: 'center', alignItems: 'center', alignSelf: 'center', cursor: 'pointer' }}
            onClick={() => goBack()}
          >
            <ChevronBack />
            <h5 style={{ color: '#0A0A0A', fontWeight: 'normal', fontSize: 18, marginBottom: 0 }} className="mx-2">
              Setting
            </h5>
          </div>

          <div className="row mt-4">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-3">
              <h5 style={{ fontSize: 16 }} className="mb-4">
                Portfolio Alert Settings
              </h5>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Gain</div>
                  <div className="mx-4">
                    <Input type="select" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}>
                      <option>5 %</option>
                      <option>10 %</option>
                      <option>15 %</option>
                      <option>20 %</option>
                    </Input>
                  </div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Loss</div>
                  <div className="mx-4">
                    <Input type="select" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}>
                      <option>5 %</option>
                      <option>10 %</option>
                      <option>15 %</option>
                      <option>20 %</option>
                    </Input>
                  </div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>

              <div className="mt-4">
                <div className="my-2">
                  <Input type="checkbox" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}></Input>
                  <span className="mx-2">Risk Tolerance Notification</span>
                </div>
                <div style={{ color: '#404040', fontWeight: 'normal', width: '80%', fontSize: 14 }} className="mt-2">
                  Turning this on will let you know if your Risk Profile and your Portfolio Composition is matched or not.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-3">
              <h5 style={{ fontSize: 16 }} className="mb-4">
                News Notification
              </h5>
              <p style={{ color: '#9E9E9E', fontSize: 14, fontWeight: 'normal' }}>
                You will receive notification every time there is an update!
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Stock News</div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Fund News</div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Bond News</div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Macro News</div>
                </div>
                <div>
                  <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RiskProfile;
