import React, { useEffect } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import VideoIframe from '../youtube';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';

interface HeroProps {
  title?: string;
}

export const About = ({ title }: HeroProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div className={'parent-about'}>
      <div className="container-custom align-items-center h-100 ">
        <Row>
          <Col xs={12} md={5} lg={5} className="">
            <div className="d-flex h-100 align-items-center">
              <div>
                <div className="justify-content-center text-center">
                  <img src="content/images/logo-sage.webp" className="style-image-about" alt="Logo" />
                </div>
                <h3 className="fw-bold text-center my-3">SAGE CLUB</h3>
                <div className="px-3 text-about text-center">
                  Sage Club is an exclusive digital wealth management service offered in Mirae Asset Offices around the world.
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={7} lg={7} className="video-container">
            <VideoIframe videoId={'dLhXbnk7Eo8'} title={'test'} />
            <div>
              <div
                style={{ backgroundColor: colours.primaryOrange, marginTop: -7, position: 'absolute', fontSize: 14 }}
                className="py-3 px-2 text-white text-center width-label-video"
              >
                Presented by our CEO Mr. Tae Yong Shim and Head of Priority Wealth Management Ms. Fransisca Wiryasaputra.
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
