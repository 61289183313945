import * as React from 'react';

const WhatsappIcon = props => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.027 1.333C4.387 1.333 1.42 4.3 1.42 7.94c0 1.166.307 2.3.88 3.3l-.933 3.426 3.5-.92a6.61 6.61 0 0 0 3.16.807c3.64 0 6.606-2.967 6.606-6.607A6.565 6.565 0 0 0 12.7 3.273a6.544 6.544 0 0 0-4.673-1.94Zm.006 1.113c1.467 0 2.84.574 3.88 1.614a5.483 5.483 0 0 1 1.607 3.886c0 3.027-2.467 5.487-5.493 5.487-.987 0-1.954-.26-2.794-.767l-.2-.113-2.08.547.554-2.027-.134-.213a5.458 5.458 0 0 1-.84-2.92 5.508 5.508 0 0 1 5.5-5.494Zm-2.346 2.44a.609.609 0 0 0-.44.207c-.147.167-.58.573-.58 1.38 0 .813.593 1.593.666 1.707.094.113 1.174 1.78 2.834 2.486.393.18.7.28.94.354.393.126.753.106 1.04.066.32-.046.973-.4 1.113-.786.14-.387.14-.714.1-.787-.047-.067-.153-.107-.32-.18-.167-.093-.98-.493-1.127-.547-.153-.053-.246-.08-.373.08-.107.167-.427.54-.52.647-.1.113-.193.127-.353.047-.174-.087-.707-.26-1.334-.82-.493-.44-.82-.98-.92-1.147-.08-.16-.006-.26.074-.333.073-.074.18-.194.246-.294.087-.093.114-.166.167-.273.053-.113.027-.207-.013-.287-.04-.073-.374-.9-.514-1.226-.133-.32-.266-.28-.373-.287-.093 0-.2-.007-.313-.007Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default WhatsappIcon;
