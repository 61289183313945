import * as React from 'react';
import { SVGProps } from 'react';

const DownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m4 4 8 16 8-16-8 2.133L4 4Z" fill="red" />
  </svg>
);

export default DownArrow;
