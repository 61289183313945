import * as React from 'react';
import { SVGProps } from 'react';

const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M20 .8C9.396.8.8 9.396.8 20S9.396 39.2 20 39.2 39.2 30.604 39.2 20 30.604.8 20 .8Zm-4.7 27.158h-3.888V15.446H15.3v12.512ZM13.332 13.91c-1.228 0-2.022-.87-2.022-1.946 0-1.098.818-1.942 2.072-1.942s2.022.844 2.046 1.942c0 1.076-.792 1.946-2.096 1.946ZM29.5 27.958h-3.888v-6.934c0-1.614-.564-2.71-1.97-2.71-1.074 0-1.712.742-1.994 1.456-.104.254-.13.614-.13.972v7.214h-3.89v-8.52c0-1.562-.05-2.868-.102-3.992h3.378l.178 1.738h.078c.512-.816 1.766-2.02 3.864-2.02 2.558 0 4.476 1.714 4.476 5.398v7.398Z"
        fill="#002F48"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedInIcon;
