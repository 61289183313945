import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { ShimmerButton, ShimmerBadge, ShimmerThumbnail } from 'react-shimmer-effects';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import './detail.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronBack from 'app/component/icon/chevron-back';
import Toggle from 'react-toggle';
import axios from 'axios';
import { services } from 'app/util/const';
import moment from 'moment';
import { colours } from 'app/config/constant/colours';

export const DetailNews = props => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const match = useMatch('/news/:id');
  const [detailNews, setDetailNews] = useState<any>(null);
  const [loadingDetailNews, setLoadingDetailNews] = useState<any>(false);

  const goBack = () => {
    navigate(-1);
  };

  const getDetailNews = (id: any) => {
    setLoadingDetailNews(true);
    axios
      .get(`${services.api.news.detail}/${id}`)
      .then(res => {
        console.log(res?.data?.data);
        setDetailNews(res?.data?.data);
        setLoadingDetailNews(false);
      })
      .catch(err => {
        setLoadingDetailNews(false);
      });
  };

  useEffect(() => {
    console.log('id', match.params.id);
    if (match.params.id) {
      getDetailNews(match.params.id);
    }
  }, []);

  const htmlDecode = input => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />

      <div className="container mt-5 mb-5">
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
          onClick={() => goBack()}
        >
          <ChevronBack />

          <div></div>
        </div>
        {!loadingDetailNews && detailNews && (
          <>
            <h5 className="my-3 fw-bold">{detailNews.title}</h5>

            <img
              src={'/services/newsservice/api/v1/news/img/article/' + detailNews?.imageUrl}
              alt=""
              height="450"
              width="100%"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=';
              }}
            />

            {/* Author */}
            <div className="mt-3 mb-4">
              <span style={{ fontSize: 14, color: colours.greyText }}>
                {' '}
                Author : {detailNews.author} | {moment(detailNews?.publishDate).format('DD MMM YYYY')}
              </span>
            </div>

            {/* contente */}
            <div>
              <div dangerouslySetInnerHTML={{ __html: htmlDecode(detailNews?.content) }} />
            </div>

            {/* Tags*/}
            <div className="my-4">
              <p className="fw-bold">Tags</p>
              <div>
                {detailNews?.tags.length > 0 &&
                  detailNews?.tags.map((item, index) => {
                    return (
                      <>
                        <div className="mx-2 my-1 px-3 py-2 card-tags" style={{ display: 'inline-block' }}>
                          {item?.tag}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            {/* Related News */}
            <div className="my-4">
              <p className="fw-bold">Related News</p>
              <div>
                {detailNews.related.length > 0 &&
                  detailNews.related.map((item, index) => {
                    return (
                      <>
                        <a
                          key={`news-${item.id}-${item.title}}`}
                          className="card my-2 px-2 py-2"
                          style={{ cursor: 'pointer', textDecoration: 'none' }}
                          href={`/news/${item.id}`}
                        >
                          <div className="row">
                            <div className="col-5 col-md-2">
                              <img
                                src={'/services/newsservice/api/v1/news/img/article/' + item?.imageUrl}
                                alt=""
                                height="150"
                                width="100%"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=';
                                }}
                              />
                            </div>
                            <div className="col-7 col-md-10">
                              <h5>{item?.title}</h5>
                              <div dangerouslySetInnerHTML={{ __html: htmlDecode(item.shortDescription) }} />
                            </div>
                          </div>
                        </a>
                      </>
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {loadingDetailNews && (
          <>
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
            <ShimmerThumbnail height={150} rounded />
          </>
        )}
      </div>

      <Footer />
    </>
  );
};

export default DetailNews;
