import * as React from 'react';

const EmailOutlineIcon = props => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.667 4c0-.733-.6-1.333-1.333-1.333H2.667c-.733 0-1.333.6-1.333 1.333v8c0 .734.6 1.334 1.333 1.334h10.666c.734 0 1.334-.6 1.334-1.334V4Zm-1.333 0L8 7.334 2.667 4h10.666Zm0 8H2.667V5.334L8 8.667l5.334-3.333V12Z"
      fill="#F4F4F4"
    />
  </svg>
);

export default EmailOutlineIcon;
