export const services = {
  api: {
    dashboard: {
      ytd: '/services/consolidatedportfolioservice/api/v1/portfolio/dashboard?type=year-to-date',
      day: '/services/consolidatedportfolioservice/api/v1/portfolio/dashboard/one-day',
      month: '/services/consolidatedportfolioservice/api/v1/portfolio/dashboard?type=month-to-date',
      quarter: '/services/consolidatedportfolioservice/api/v1/portfolio/dashboard?type=quarter-to-date',
      stock: '/services/marketdataservice/api/v1/_search/stocks/',
      createwatchlist: '/services/marketdataservice/api/v1/price-alerts',
    },
    analys: {
      indices: '/services/marketdataservice/api/v1/indices',
      currencies: '/services/marketdataservice/api/v1/currencies',
      commodities: '/services/marketdataservice/api/v1/commodities',
      searchreport: 'services/marketdataservice/api/v1/_search/research-reports',
      stockanyalys: '/services/marketdataservice/api/v1/stocks-analysis',
    },
    portfolio: {
      summary: `/services/consolidatedportfolioservice/api/v1/portfolio/summary/chart`,
      stock: `/services/consolidatedportfolioservice/api/v1/portfolio/stock`,
      bond: '/services/consolidatedportfolioservice/api/v1/portfolio/bond',
      mutualfund: `/services/consolidatedportfolioservice/api/v1/portfolio/mutualfund`,
      historyStock: `/services/consolidatedportfolioservice/api/trx-history-stocks`,
      gainLossStock: `/services/consolidatedportfolioservice/api/trx-history-stocks/gain-loss`,
    },
    report: {
      addhoc: '/services/clientservice/api/v1/pwm-customers/get-client-monthly-pdf',
      yearlytax: '/services/clientservice/api/v1/pwm-customers/get-tax-pdf',
    },
    notification: {
      list: '/services/commonservice/api/v1/pwm-notifications',
    },
    riskprofile: {
      list: '/services/clientservice/api/v1/risk-questions',
      categories: '/services/clientservice/api/v1/risk-categories',
      save: '/services/clientservice/api/v1/pwm-customer-risk-answers',
    },
    stock: {
      candleData: '/services/technicalanalysis/api/v1/historicals',
      candleHeader: '/services/marketdataservice/api/v1/stocks',
    },
    news: {
      list: '/services/newsservice/api/v1/pwm/news',
      detail: '/services/newsservice/api/v1/pwm/news',
    },
  },
};
