import React, { useEffect } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import ProfitArrow from '../icon/profitarrow';

export const NewsList = (data: any) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div className="py-3">
      <div className="row">
        <div className="col">
          <img src={data.data.img} style={{ width: '100%', height: '100%' }} alt="" />
        </div>
        <div className="col-8">
          <div style={{ fontSize: 15, color: '#404040', marginBottom: 4 }}>{data.data.title}</div>
          <div style={{ fontSize: 13, color: '#616161', marginBottom: 4 }}>{data.data.summary}</div>
          <div style={{ fontSize: 12, color: '#9E9E9E', marginBottom: 4 }}>{data.data.date}</div>
        </div>
      </div>
      <hr className="mt-4" />
    </div>
  );
};

export default NewsList;
