import * as React from 'react';
import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M20 .8C9.396.8.8 9.396.8 20S9.396 39.2 20 39.2 39.2 30.604 39.2 20 30.604.8 20 .8Zm4.548 13.268h-2.886c-.342 0-.722.45-.722 1.048V17.2h3.61l-.546 2.972H20.94v8.922h-3.406v-8.922h-3.09V17.2h3.09v-1.748c0-2.508 1.74-4.546 4.128-4.546h2.886v3.162Z"
        fill="#002F48"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FacebookIcon;
