import * as React from 'react';

const InterfaceSettingIcon = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.304 1.814a.485.485 0 0 1 .188-.241c.2-.123.658-.323 1.508-.323s1.308.2 1.508.323c.09.055.149.143.188.241.137.339.472 1.2.67 1.968A6.51 6.51 0 0 1 14.1 4.804c.753-.21 1.653-.345 2.01-.394a.47.47 0 0 1 .298.045c.205.116.604.42 1.029 1.17.425.75.484 1.254.48 1.493a.492.492 0 0 1-.112.286c-.219.29-.784 1.016-1.338 1.575a6.841 6.841 0 0 1 0 2.042c.554.559 1.119 1.285 1.338 1.575.064.084.11.18.112.286.004.239-.055.743-.48 1.493-.425.75-.824 1.054-1.029 1.17a.47.47 0 0 1-.299.044c-.356-.048-1.256-.184-2.009-.393a6.51 6.51 0 0 1-1.734 1.022c-.198.768-.533 1.63-.67 1.968a.486.486 0 0 1-.188.241c-.2.123-.657.323-1.508.323-.85 0-1.308-.2-1.508-.323a.486.486 0 0 1-.188-.241c-.136-.339-.471-1.2-.67-1.968A6.51 6.51 0 0 1 5.9 15.196c-.752.21-1.653.345-2.009.393a.47.47 0 0 1-.299-.045c-.205-.115-.604-.419-1.029-1.169-.425-.75-.483-1.254-.48-1.493a.492.492 0 0 1 .112-.286c.22-.29.784-1.016 1.339-1.575a6.844 6.844 0 0 1 0-2.042c-.555-.559-1.12-1.285-1.34-1.575a.492.492 0 0 1-.11-.286c-.004-.239.054-.743.48-1.493.424-.75.823-1.054 1.028-1.17a.47.47 0 0 1 .3-.045c.355.05 1.256.184 2.008.394a6.507 6.507 0 0 1 1.735-1.022c.198-.768.533-1.63.669-1.968Z"
      fill="#E5E6E5"
    />
    <path d="M13.334 10a3.333 3.333 0 1 1-6.667 0 3.333 3.333 0 0 1 6.667 0Z" fill="#D2D3D1" />
  </svg>
);

export default InterfaceSettingIcon;
