import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import YouTube from 'react-youtube';
import VideoIframe from 'app/component/youtube';
import { ButtonComponent } from 'app/component';
import { jsonMap } from 'app/config/constant/map';
import './portfolio.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import DownloadAddHoc from 'app/component/icon/downloadaddhoc';
import CardPortfolio from 'app/component/CardPortfolio/CartPortfolio';
import NewsList from 'app/component/NewsList/NewsList';
import WatchList from 'app/component/WatchList/WatchList';

export const Portfolio = () => {
  const account = useAppSelector(state => state.authentication.account);
  const [activeTab, setActiveTab] = useState<any>(1);
  const [open, setOpen] = useState('');
  const [activeTabse, setActiveTabs] = useState(1);
  const toggleSet = (id: any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const portfolioData = [
    {
      id: 1,
      title: 'Daily',
      bgColor: '#EA6D26',
      value: '27.83 M',
      percentDiff: '-24.558 (-0.2%)',
    },
    {
      id: 2,
      title: 'Month To Date',
      bgColor: '#EA6D26',
      value: '27.83 M',
      percentDiff: '-24.558 (-0.2%)',
    },
    {
      id: 3,
      title: 'Quarter To Date',
      bgColor: '#002F48',
      value: '27.83 M',
      percentDiff: '-24.558 (-0.2%)',
      textColorDiff: '#3BDB63',
    },
    {
      id: 4,
      title: 'Year To Date',
      bgColor: '#002F48',
      value: '27.83 M',
      percentDiff: '-24.558 (-0.2%)',
      textColorDiff: '#FF0000',
    },
  ];

  const newsData = [
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar egestas at ultrices et... ',
      date: '16/09/2021',
      img: 'https://usatodayhss.com/wp-content/uploads/sites/96/2019/05/img.v1.jpg',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar egestas at ultrices et... ',
      date: '16/09/2021',
      img: 'https://usatodayhss.com/wp-content/uploads/sites/96/2019/05/img.v1.jpg',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pulvinar egestas at ultrices et... ',
      date: '16/09/2021',
      img: 'https://usatodayhss.com/wp-content/uploads/sites/96/2019/05/img.v1.jpg',
    },
  ];

  const watchData = [
    {
      emiten: 'BIMA',
      target_buy: '240',
      last_price: '304',
    },
    {
      emiten: 'BBCA',
      target_buy: '6900',
      last_price: '7800',
    },
  ];

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />
      <div className="bg-white pb-5 pt-5">
        <div className="container ">
          <p className="text-center">Total Wealth Value</p>
          <div style={{ width: 235 }} className="mx-auto">
            <span style={{ fontSize: 12, position: 'absolute' }}>Rp</span>
            <h3 className="text-center">{'  '}12,279,378,290</h3>
          </div>
          <div style={{ color: colours.primaryOrange, cursor: 'pointer' }} className="text-center">
            <DownloadAddHoc /> Request for Ad-hoc Statement
          </div>
        </div>

        <div className="my-5 mx-auto" style={{ width: '90%' }}>
          <div>
            <h5>
              <span className="fw-bold">Portfolio</span> <span style={{ fontWeight: 'lighter' }}>Performance</span>
            </h5>
          </div>

          <div className="row my-3">
            {portfolioData.map((item, index) => {
              return (
                <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                  <CardPortfolio
                    title={item.title}
                    backgroundColor={item.bgColor}
                    value={item.value}
                    percentDiff={item.percentDiff}
                    textColorDiff={item.textColorDiff}
                  />
                </div>
              );
            })}
          </div>

          {/*  */}

          <div className="row mt-5">
            <div className="col-4">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>Watchlist</h5>
                <div style={{ color: colours.primaryOrange, cursor: 'pointer' }}>View All</div>
              </div>
              <div>
                {watchData.map((item, index) => {
                  return <WatchList data={item} />;
                })}
              </div>
            </div>
            <div className="col-4">
              <h5>Latest News</h5>
              <div>
                {newsData.map((item, index) => {
                  return <NewsList data={item} />;
                })}
              </div>
            </div>
            <div className="col-4">
              <h5>
                <span className="fw-bold">Technical </span>
                <span style={{ fontWeight: 'lighter' }}>Analysis</span>
              </h5>

              <div>
                <div className="row">
                  <div className="col-12">
                    <FormGroup>
                      <Input type="text" name="text" id="examplePassword" placeholder="Search Stock" />
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <FormGroup>
                      <Label for="examplePassword">Technical Analysis</Label>
                      <Input type="select" name="select" id="exampleSelect" placeholder="text">
                        <option>All</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Market Cap</Label>
                      <Input type="select" name="select" id="exampleSelect" placeholder="text">
                        <option>All</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Transaction</Label>
                      <Input type="select" name="select" id="exampleSelect" placeholder="text">
                        <option>All</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Time Frame</Label>
                      <Input type="select" name="select" id="exampleSelect" placeholder="text">
                        <option>All</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Index</Label>
                      <Input type="select" name="select" id="exampleSelect" placeholder="text">
                        <option>All</option>
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              </div>

              {/*  */}

              <div className="card bg-white px-3">
                <table>
                  <thead>
                    <tr>
                      <td className="py-3 px-2" style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>
                        Stock
                      </td>
                      <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Current Price</td>
                      <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Resistance</td>
                      <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Support</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-2 py-4">BBNI</td>
                      <td>
                        <div style={{ color: '#D8382E' }}>6,850</div>
                        <div style={{ color: '#D8382E' }}>250 (-3.52%)</div>
                      </td>
                      <td>
                        <div>R1 7,518</div>
                        <div>R1 7,518</div>
                      </td>
                      <td>
                        <div>S1 6,726</div>
                        <div>S1 6,726</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 py-4">BBNI</td>
                      <td>
                        <div style={{ color: '#3BDB63' }}>6,850</div>
                        <div style={{ color: '#3BDB63' }}>250 (-3.52%)</div>
                      </td>
                      <td>
                        <div>R1 7,518</div>
                        <div>R1 7,518</div>
                      </td>
                      <td>
                        <div>S1 6,726</div>
                        <div>S1 6,726</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{borderBottom: '1px solid #221815'}}>
        <div className="container">
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {
              tabsDashboard.map((item, index) => {
                return <div className={activeTab == item.id ? "mx-3 active-tabs" : "mx-3 default-tabs"} style={{cursor: 'pointer'}} onClick={() => setActiveTab(item.id)}>{item.name}</div>
              })
            }   
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default Portfolio;
