import * as React from 'react';

const InterfaceLogoutIcon = props => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.794 1.957c-.75.09-1.3.63-1.408 1.377-.162 1.12-.347 3.187-.347 6.768 0 3.236.084 5.236.166 6.418.066.936.733 1.654 1.667 1.747.874.088 2.17.168 4 .168s3.126-.08 4-.168c.934-.093 1.601-.81 1.667-1.747.082-1.182.166-3.182.166-6.418 0-3.237-.084-5.236-.166-6.418-.066-.936-.733-1.654-1.667-1.748-.874-.088-2.17-.167-4-.167-1.976 0-3.27.093-4.078.188Z"
      fill="#E5E6E5"
    />
    <path
      d="M15.79 13.914c-.628.53-1.405.183-1.451-.64a47.76 47.76 0 0 1-.061-1.753c-3.564-.036-6.34-.12-7.639-.165a.839.839 0 0 1-.806-.844v-.935c0-.449.344-.819.789-.842a212.68 212.68 0 0 1 7.656-.249c.013-.724.035-1.302.06-1.76.047-.824.824-1.17 1.453-.64.374.315.837.724 1.393 1.25.932.883 1.467 1.546 1.77 1.99.284.416.284.931 0 1.347-.303.444-.838 1.107-1.77 1.99-.557.526-1.019.936-1.394 1.251Z"
      fill="#D2D3D1"
    />
  </svg>
);

export default InterfaceLogoutIcon;
