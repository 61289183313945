import * as React from 'react';

const EmptyAnalystIcon = props => (
  <svg width={136} height={120} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M98.766 109.781s5.324 7.985 6.211 7.539c.887-.447 12.023-7.933 10.395-8.673-1.627-.741-7.492.792-7.492.792l-4.383-5.25-4.73 5.592Z"
        fill="#fff"
      />
      <path
        d="M104.946 117.587c-1.05 0-4.032-4.11-6.4-7.659a.265.265 0 0 1 0-.315l4.725-5.618a.26.26 0 0 1 .31-.07.277.277 0 0 1 .089.07l4.279 5.145c.987-.247 5.953-1.438 7.507-.73a.531.531 0 0 1 .315.468c.032 1.743-10.227 8.442-10.705 8.678a.314.314 0 0 1-.12.031Zm-5.854-7.785c2.347 3.501 5.203 7.239 5.775 7.276.341-.184 3.008-1.927 5.612-3.859 4.762-3.522 4.778-4.263 4.767-4.336-1.276-.572-5.717.388-7.324.808a.254.254 0 0 1-.267-.089l-4.2-5.029-4.363 5.229Z"
        fill="#404040"
      />
      <path
        d="M80.439 108.006s-2.515 9.413-1.827 10.201c.687.787 16.952 1.575 16.359.693-.594-.882-8.243-5.817-8.243-5.817l.73-4.41-7.02-.667Z"
        fill="#fff"
      />
      <path
        d="M91.432 119.53c-4.772 0-12.495-.551-13.015-1.15-.724-.829 1.166-8.2 1.764-10.442a.269.269 0 0 1 .279-.189l7.024.661a.29.29 0 0 1 .184.105.253.253 0 0 1 .047.2l-.698 4.242c1.26.814 7.597 4.93 8.174 5.775a.335.335 0 0 1-.09.467c-.283.242-1.732.331-3.669.331Zm-10.8-11.23c-1.17 4.431-2.162 9.235-1.82 9.749.834.646 13.89 1.308 15.791.835-.924-.892-5.176-3.743-8.017-5.57a.252.252 0 0 1-.115-.263l.682-4.131-6.52-.62Z"
        fill="#404040"
      />
      <path d="M70.915 75.654H50.923v41.67h19.992v-41.67Z" fill="#fff" />
      <path
        d="M70.92 117.582H50.934a.264.264 0 0 1-.263-.263V75.655a.263.263 0 0 1 .263-.262H70.93a.262.262 0 0 1 .262.262v41.664a.267.267 0 0 1-.08.189.265.265 0 0 1-.193.074Zm-19.734-.525h19.472v-41.14H51.186v41.14Z"
        fill="#404040"
      />
      <path d="M45.353 65.082H25.36v52.238h19.992V65.082Z" fill="#fff" />
      <path
        d="M45.353 117.581H25.36a.264.264 0 0 1-.263-.263V65.081a.262.262 0 0 1 .263-.263h19.992a.262.262 0 0 1 .262.263v52.237a.262.262 0 0 1-.262.263Zm-19.73-.525H45.09V65.343H25.623v51.713Z"
        fill="#404040"
      />
      <path d="M21.024 44.77H1.032v72.549h19.992v-72.55Z" fill="#fff" />
      <path
        d="M21.009 117.581H1.059a.262.262 0 0 1-.262-.262V44.774a.262.262 0 0 1 .262-.262h19.95a.263.263 0 0 1 .262.262v72.545a.262.262 0 0 1-.262.262Zm-19.73-.525h19.467V45.032H1.295l-.016 72.024ZM76.685 66.068l30.802 40.079-9.067 6.148-41.554-46.227h19.819Z"
        fill="#404040"
      />
      <path
        d="M98.42 112.558a.266.266 0 0 1-.194-.089l-41.56-46.227a.257.257 0 0 1-.041-.278.268.268 0 0 1 .241-.157h19.819a.257.257 0 0 1 .205.1l30.807 40.078a.282.282 0 0 1 .052.205.257.257 0 0 1-.115.173l-9.067 6.137a.248.248 0 0 1-.147.058ZM57.47 66.332l40.992 45.643 8.647-5.89-30.555-39.753H57.47Z"
        fill="#404040"
      />
      <path
        d="M47.673 66.783s2.725 7.964 5.697 11.22c2.971 3.254 27.662 12.415 27.662 12.415l-2.247 19.777 11.413 1.213S98 89.825 95.354 86.276c-2.646-3.549-31.5-22.575-31.5-22.575l-16.18 3.082Z"
        fill="#404040"
      />
      <path
        d="m90.198 111.67-11.44-1.213a.27.27 0 0 1-.178-.094.272.272 0 0 1-.052-.194l2.22-19.578c-2.483-.929-24.675-9.266-27.573-12.416-2.971-3.255-5.638-10.983-5.775-11.308a.258.258 0 0 1 0-.216.278.278 0 0 1 .179-.13l16.19-3.077a.268.268 0 0 1 .19.036c1.228.783 28.975 19.095 31.6 22.644 1.15 1.538.63 6.3-1.544 14.117-1.575 5.775-3.554 11.203-3.575 11.256a.262.262 0 0 1-.242.173Zm-11.12-1.706 10.942 1.16c.362-1.013 2.068-5.875 3.496-11.025 2.625-9.544 2.347-12.7 1.628-13.65-2.52-3.37-29.563-21.283-31.337-22.454l-15.782 3.003c.525 1.449 2.956 8.011 5.539 10.841 2.898 3.15 27.3 12.259 27.562 12.348a.262.262 0 0 1 .168.278l-2.215 19.499Z"
        fill="#404040"
      />
      <path
        d="M78.076 37.71s5.187 11.55 5.975 11.628l3.202-4.977s.294-3.397 1.181-4.778c.888-1.38 1.875-.987 1.875-.987s.876-1.659 1.958-1.853c1.081-.194 4.457 1.895 2.478 3.187-1.98 1.291-8.369 19.76-10.736 19.992-7.524.724-12.868-20.081-12.816-20.144l6.883-2.069Z"
        fill="#fff"
      />
      <path
        d="M83.61 60.198c-2.893 0-5.775-2.908-8.505-8.662-2.1-4.426-4.426-11.613-4.1-11.944a.237.237 0 0 1 .11-.063l6.888-2.1a.273.273 0 0 1 .315.142c2.23 4.966 4.866 10.384 5.67 11.356l3.008-4.683c.047-.494.367-3.502 1.218-4.83.74-1.155 1.575-1.203 1.963-1.145.273-.457 1.05-1.633 2.043-1.811.892-.163 2.856.908 3.297 2.016a1.275 1.275 0 0 1-.63 1.643c-.804.525-2.625 4.604-4.358 8.552-3.034 6.852-5.124 11.35-6.505 11.482-.137.024-.275.04-.414.047ZM71.498 39.96a68.16 68.16 0 0 0 3.59 10.29c2.017 4.488 5.188 9.77 8.89 9.408 1.175-.116 4.005-6.505 6.073-11.178 1.938-4.383 3.618-8.169 4.547-8.772.593-.389.525-.762.43-1.014-.362-.918-2.1-1.81-2.714-1.695-.787.141-1.549 1.291-1.774 1.716a.268.268 0 0 1-.331.126c-.026 0-.803-.283-1.575.882-.84 1.308-1.14 4.626-1.14 4.657a.237.237 0 0 1-.041.12L84.27 49.5a.268.268 0 0 1-.252.12c-.803-.1-4.116-7.197-6.09-11.55l-6.431 1.89Z"
        fill="#404040"
      />
      <path
        d="M54.635 7.258c-.354.32-.688.66-1.003 1.019-1.155 1.302-1.8 2.966-2.42 4.588-.94 2.463-1.507 7.261-3.045 9.23-1.69 2.158-5.492 1.223-6.977 3.77-.95 1.627-.174 3.737-.688 5.549-.94 3.276-5.376 4.021-7.413 6.756-1.628 2.18-1.418 5.25-.667 7.844.525 1.8 1.292 3.59 2.625 4.882 4.368 4.169 12.758.725 15.75-3.528 1.496-2.147 2.342-4.987 4.678-6.163 1.696-.85 3.706-.562 5.602-.63a14.097 14.097 0 0 0 6-1.575c1.943-1.013 3.822-3.108 3.061-5.16-.478-1.277-1.869-2.1-2.1-3.45-.357-1.848 1.544-3.218 2.872-4.552 3.47-3.502 3.433-9.854-.079-13.314a5.775 5.775 0 0 0-.394-7.313 3.097 3.097 0 0 0-1.517-.94 5.056 5.056 0 0 0-1.774.058c-4.872.64-8.736-.473-12.511 2.93Z"
        fill="#fff"
      />
      <path
        d="M40.171 53.044a7.418 7.418 0 0 1-5.281-1.969c-1.203-1.144-2.069-2.735-2.72-5.003-.65-2.268-1.097-5.649.709-8.07.892-1.201 2.226-2.026 3.517-2.824 1.717-1.05 3.34-2.063 3.854-3.848a8.161 8.161 0 0 0 .178-2.357c0-1.108-.047-2.258.525-3.25.84-1.438 2.352-1.832 3.807-2.215 1.26-.331 2.451-.64 3.192-1.575.95-1.213 1.543-3.639 2.063-5.775a31.99 31.99 0 0 1 .945-3.381c.598-1.575 1.27-3.313 2.467-4.668.33-.37.68-.72 1.05-1.05 2.799-2.52 5.592-2.625 8.82-2.74 1.218-.047 2.473-.095 3.812-.273l.2-.032a4.54 4.54 0 0 1 1.664 0 3.318 3.318 0 0 1 1.648 1.019 6.058 6.058 0 0 1 .525 7.47 10.039 10.039 0 0 1-.079 13.52c-.215.22-.446.44-.682.66-1.176 1.109-2.384 2.253-2.1 3.676a4.115 4.115 0 0 0 1.05 1.78c.436.482.79 1.031 1.05 1.627.777 2.1-1.013 4.347-3.182 5.486a14.452 14.452 0 0 1-6.116 1.623h-1.391c-1.412 0-2.872 0-4.106.593-1.575.782-2.42 2.341-3.328 3.985-.389.703-.793 1.433-1.25 2.1-1.958 2.798-6.069 5.097-9.77 5.47-.367.005-.724.021-1.07.021Zm14.642-45.59c-.348.312-.678.645-.987.997-1.134 1.28-1.764 2.924-2.367 4.51a29.636 29.636 0 0 0-.93 3.312c-.561 2.305-1.139 4.683-2.157 5.98-.85 1.087-2.184 1.438-3.476 1.774-1.412.368-2.75.72-3.48 1.974-.526.861-.484 1.885-.463 2.972a8.579 8.579 0 0 1-.2 2.51c-.566 1.984-2.356 3.086-4.084 4.152-1.244.772-2.535 1.575-3.37 2.688-1.675 2.252-1.265 5.402-.625 7.618.64 2.215 1.444 3.675 2.578 4.767 1.843 1.759 4.247 1.932 5.938 1.759 3.564-.352 7.512-2.562 9.392-5.25.44-.636.84-1.35 1.223-2.043.908-1.643 1.848-3.35 3.55-4.2 1.348-.672 2.87-.661 4.34-.645h1.371a13.98 13.98 0 0 0 5.89-1.575c1.97-1.05 3.613-3.024 2.935-4.836a5.476 5.476 0 0 0-.97-1.475 4.594 4.594 0 0 1-1.187-2.021c-.32-1.69 1.05-2.993 2.273-4.142.23-.216.462-.43.672-.646a9.508 9.508 0 0 0-.074-12.941.262.262 0 0 1 0-.347 5.502 5.502 0 0 0-.378-6.946 2.84 2.84 0 0 0-1.386-.871 4.127 4.127 0 0 0-1.475.037l-.205.031c-1.36.184-2.625.231-3.858.278-3.15.11-5.833.21-8.49 2.625v-.047Z"
        fill="#404040"
      />
      <path d="M71.624 29.15s6.447 6.7 8.242 10.857c-3.086.31-6.127.974-9.061 1.98l.819-12.837Z" fill="#F37F26" />
      <path
        d="M61.717 25.171s-5.985-2.447-13.408 3.78c-3.308 2.809-4 5.849-5.403 8.998 1.05 10.553 4.767 28.828 4.767 28.828l29.012-.714s-2-31.998-4.32-35.947c-2.321-3.947-10.648-4.945-10.648-4.945Z"
        fill="#F37F26"
      />
      <path d="M57.785 26.51c.451.882 5.428 19.897 5.428 19.897l2.683-17.66-8.111-2.237Z" fill="#404040" />
      <path
        d="M63.213 46.67a.258.258 0 0 1-.252-.2c-1.386-5.281-5.05-19.141-5.407-19.845a.252.252 0 0 1 .031-.283.269.269 0 0 1 .273-.09L65.97 28.5a.257.257 0 0 1 .189.288L63.47 46.45a.253.253 0 0 1-.242.22h-.016ZM58.2 26.893c.845 2.656 3.927 14.338 4.945 18.218l2.457-16.176-7.402-2.042Z"
        fill="#404040"
      />
      <path
        d="M69.597 11.175s1.355 4.3.494 7.492a4.725 4.725 0 0 1-3.775 3.533l-.22 5.701s-.09 2.92-3.644 2.326a6.137 6.137 0 0 1-4.667-3.717v-10.5s4.142-2.173 3.937-5.953c.006-.006 4.73 2.514 7.875 1.118Z"
        fill="#fff"
      />
      <path
        d="M63.35 30.568a5.965 5.965 0 0 1-.94-.079 6.347 6.347 0 0 1-4.872-3.895.238.238 0 0 1 0-.084v-10.5a.252.252 0 0 1 .142-.231c.037 0 3.99-2.142 3.796-5.707a.269.269 0 0 1 .12-.236.263.263 0 0 1 .263 0c.047 0 4.651 2.441 7.644 1.108a.263.263 0 0 1 .357.162c.058.179 1.37 4.41.493 7.64a5.029 5.029 0 0 1-3.77 3.674l-.214 5.497a2.95 2.95 0 0 1-.998 2.021 3.066 3.066 0 0 1-2.021.63Zm-5.303-4.11a5.917 5.917 0 0 0 4.437 3.512c1.097.183 1.947.036 2.53-.441.471-.421.76-1.008.809-1.638l.23-5.691a.263.263 0 0 1 .232-.252 4.432 4.432 0 0 0 3.549-3.36c.708-2.625-.137-6.08-.41-7.067-2.704.998-6.258-.483-7.434-1.05-.094 3.287-3.223 5.25-3.943 5.68v10.306Z"
        fill="#404040"
      />
      <path d="M59.796 15.344s-1.612-4.027-3.675-1.607c-2.064 2.42.708 5.25 2.472 5.098" fill="#fff" />
      <path
        d="M58.425 19.107c-1.05 0-2.341-.872-2.95-2.058a3.008 3.008 0 0 1 .43-3.486 1.952 1.952 0 0 1 1.796-.846c1.407.237 2.3 2.436 2.336 2.526a.261.261 0 0 1-.24.39.263.263 0 0 1-.243-.196c-.226-.557-1.013-2.048-1.937-2.2a1.465 1.465 0 0 0-1.313.667 2.484 2.484 0 0 0-.367 2.903c.551 1.05 1.727 1.853 2.625 1.764a.262.262 0 0 1 .283.242.257.257 0 0 1-.236.283l-.184.01ZM67.245 18.761a4.725 4.725 0 0 1-1.233-.194.268.268 0 0 1-.174-.33.267.267 0 0 1 .331-.174 2.86 2.86 0 0 0 1.633.074c.068-.231-.635-1.602-1.533-2.904a.258.258 0 0 1 .157-.404.265.265 0 0 1 .273.105c.42.604 1.775 2.625 1.575 3.37a.456.456 0 0 1-.252.31c-.245.105-.51.155-.777.147Z"
        fill="#404040"
      />
      <path
        d="M65.823 20.477a3.932 3.932 0 0 1-1.014-.136 4.2 4.2 0 0 1-2.388-1.77.263.263 0 0 1 .483-.2 3.786 3.786 0 0 0 2.136 1.487c1.098.273 2.247-.037 3.418-.924a.261.261 0 0 1 .368.052.262.262 0 0 1-.053.368 4.899 4.899 0 0 1-2.95 1.123ZM66.316 22.2s-3.06.262-4.793-.253c0 0 1.738 4.568 4.636 4.657l.157-4.405Z"
        fill="#404040"
      />
      <path
        d="M66.159 26.867c-3.04-.095-4.799-4.63-4.872-4.825a.284.284 0 0 1 .052-.273.268.268 0 0 1 .268-.073c1.664.498 4.667.241 4.699.241a.273.273 0 0 1 .267.165c.013.034.019.071.016.108l-.157 4.405a.262.262 0 0 1-.273.252Zm-4.2-4.541c.525 1.097 1.916 3.722 3.932 3.995l.136-3.838a17.68 17.68 0 0 1-4.068-.157ZM64.526 15.189c.247-.004.441-.36.434-.794-.006-.435-.212-.785-.458-.78-.247.003-.44.359-.434.793.007.435.212.785.458.781ZM68.657 15.124c.246-.003.44-.359.434-.794-.007-.435-.212-.784-.459-.78-.246.003-.44.36-.434.794.007.435.212.784.459.78Z"
        fill="#404040"
      />
      <path
        d="M42.906 37.95s-6.037 19.77-1.79 24.375c2.945 3.197 10.5-.268 13.787-1.533 16.243-6.232 21.992-11.33 20.695-11.088.4-1.334-3.675.525-4.357.782-.273-1.092 1.722-3.013 2.845-3.076 1.124-.063 2.415.394 2.588-.72.095-.635-.525-1.28-1.076-1.575-.551-.294-5.308-.572-6.3.331-.65.583-.982 1.45-1.575 2.069-.66.576-1.44.997-2.284 1.233a142.94 142.94 0 0 1-9.775 3.119c-1.575.452-5.366 1.323-5.366 1.323l1.896-11.676"
        fill="#fff"
      />
      <path
        d="M44.77 63.92c-1.538 0-2.903-.383-3.848-1.412-4.295-4.662 1.486-23.819 1.732-24.627a.267.267 0 0 1 .331-.179.267.267 0 0 1 .173.33c-.057.195-5.943 19.678-1.848 24.12 2.541 2.76 8.983.194 12.443-1.182l1.05-.42c13.597-5.213 19.756-9.618 20.611-10.668a.28.28 0 0 1-.068-.252.285.285 0 0 0 0-.105c-.063-.052-.525-.278-3.817 1.124l-.204.084a.268.268 0 0 1-.22 0 .284.284 0 0 1-.137-.174 2.179 2.179 0 0 1 .525-1.659c.598-.808 1.695-1.695 2.556-1.743a8.31 8.31 0 0 1 .888 0c.966.058 1.37.027 1.454-.525.084-.55-.415-1.05-.935-1.296-.52-.247-5.103-.525-6.032.299a4.926 4.926 0 0 0-.814 1.05 4.724 4.724 0 0 1-3.15 2.31c-3.207 1.15-6.504 2.2-9.796 3.15-1.575.44-5.34 1.312-5.376 1.323a.258.258 0 0 1-.305-.171.261.261 0 0 1-.01-.128l1.895-11.676a.256.256 0 0 1 .3-.216.267.267 0 0 1 .22.3L50.55 52.869c1.003-.236 3.702-.876 4.972-1.233a146.57 146.57 0 0 0 9.765-3.114 4.256 4.256 0 0 0 2.882-2.1 5.55 5.55 0 0 1 .909-1.144c1.128-1.003 5.948-.709 6.615-.373.666.336 1.333 1.097 1.218 1.848-.168 1.05-1.14 1.008-2 .96a6.387 6.387 0 0 0-.835 0c-.588.032-1.575.72-2.163 1.534a2.4 2.4 0 0 0-.441.887c2.551-1.05 3.774-1.354 4.2-.96.102.091.164.22.173.356a.331.331 0 0 1 .136.184c.252.887-6.599 5.828-21 11.366l-1.05.41c-2.514.971-6.147 2.43-9.16 2.43Z"
        fill="#404040"
      />
      <path
        d="M52.146 41.787H52.1a.257.257 0 0 1-.21-.304l1.223-6.825a.268.268 0 0 1 .305-.215.263.263 0 0 1 .21.304l-1.223 6.825a.258.258 0 0 1-.258.215ZM89.175 43.44a.262.262 0 0 1-.205-.425c.745-.918.205-2.976.2-2.997a.257.257 0 0 1 .162-.31.262.262 0 0 1 .326.131s.525 1.124 1.365 1.208c.12-.194-.326-1.286-.94-2.316a.268.268 0 0 1 .09-.362.268.268 0 0 1 .361.095c.405.677 1.308 2.315.945 2.882a.526.526 0 0 1-.525.22 1.906 1.906 0 0 1-1.107-.561 3.37 3.37 0 0 1-.483 2.336.251.251 0 0 1-.19.1Z"
        fill="#404040"
      />
      <path
        d="M91.941 50.722a6.405 6.405 0 0 1-5.034-2.23 6.614 6.614 0 0 1-1.323-5.524c.766-4.2 4.83-7.665 9.056-7.665a6.399 6.399 0 0 1 5.035 2.237 6.61 6.61 0 0 1 1.317 5.523c-.761 4.22-4.824 7.66-9.05 7.66Zm2.704-14.873c-3.985 0-7.817 3.245-8.542 7.235a6.107 6.107 0 0 0 1.203 5.092 5.896 5.896 0 0 0 4.635 2.042c3.985 0 7.812-3.244 8.537-7.229a6.085 6.085 0 0 0-1.202-5.092 5.896 5.896 0 0 0-4.63-2.048Z"
        fill="#C2C2C2"
      />
      <path
        d="M99.36 53.816H67.093a2.478 2.478 0 0 1-2.284-3.428l6.825-16.322a2.468 2.468 0 0 1 2.284-1.523h32.246a2.477 2.477 0 0 1 2.061 1.102 2.474 2.474 0 0 1 .222 2.326l-6.825 16.323a2.463 2.463 0 0 1-2.262 1.522ZM73.897 33.068a1.947 1.947 0 0 0-1.8 1.202L65.27 50.587a1.952 1.952 0 0 0 1.801 2.704H99.34a1.951 1.951 0 0 0 1.8-1.202l6.825-16.317a1.947 1.947 0 0 0-.177-1.834 1.946 1.946 0 0 0-1.623-.87H73.897Z"
        fill="#404040"
      />
      <path
        d="M83.274 36.28h-8.862a.263.263 0 0 1-.263-.262.268.268 0 0 1 .263-.262h8.862a.268.268 0 0 1 .262.262.263.263 0 0 1-.262.263ZM82.087 38.218H73.23a.263.263 0 0 1 0-.525h8.857a.263.263 0 1 1 0 .525Z"
        fill="#404040"
      />
      <path
        d="M91.91 106.41a.273.273 0 0 1-.236-.147c-.042-.084-.042-.084 3.921-15.54a.27.27 0 0 1 .525.13s-3.916 15.257-3.937 15.357a.279.279 0 0 1-.226.2h-.047ZM86.634 79.005a.279.279 0 0 1-.142-.042l-14.873-9.76a.258.258 0 0 1-.074-.362.262.262 0 0 1 .362-.073l14.868 9.754a.267.267 0 0 1 .074.368.273.273 0 0 1-.215.115Z"
        fill="#fff"
      />
      <path d="M98.535 70.247a17.093 17.093 0 1 0 34.188 0h-17.094V53.174a17.1 17.1 0 0 0-17.094 17.073Z" fill="#F37F26" />
      <path d="M118.391 50.629v17.094h17.094a17.093 17.093 0 0 0-17.094-17.094Z" fill="#fff" />
      <path
        d="M126.077 43.493h-10.206a.247.247 0 1 1 0-.488h10.206a.247.247 0 0 1 0 .488ZM129.92 43.493h-1.937a.244.244 0 0 1-.211-.244.245.245 0 0 1 .211-.244h1.937a.247.247 0 1 1 0 .488ZM130.833 39.168h-15.051a.247.247 0 0 1 0-.488h15.051a.244.244 0 0 1 .211.244.245.245 0 0 1-.211.244ZM120.722 34.842h-4.888a.242.242 0 0 1-.241-.241.23.23 0 0 1 .017-.093.216.216 0 0 1 .052-.08.233.233 0 0 1 .172-.07h4.888a.242.242 0 0 1 .241.242.245.245 0 0 1-.241.242ZM132.44 34.995h-8.699a.24.24 0 0 1-.173-.07.247.247 0 0 1-.052-.078.243.243 0 0 1 .225-.335h8.699a.248.248 0 0 1 .241.241.242.242 0 0 1-.241.242Z"
        fill="#C2C2C2"
      />
      <path
        d="M126.077 43.493h-10.206a.247.247 0 1 1 0-.488h10.206a.247.247 0 0 1 0 .488v0ZM129.92 43.493h-1.937a.244.244 0 0 1-.211-.244.245.245 0 0 1 .211-.244h1.937a.247.247 0 1 1 0 .488v0ZM130.833 39.168h-15.051a.247.247 0 0 1 0-.488h15.051a.244.244 0 0 1 .211.244.245.245 0 0 1-.211.244v0ZM120.722 34.842h-4.888a.242.242 0 0 1-.241-.241.23.23 0 0 1 .017-.093.216.216 0 0 1 .052-.08.233.233 0 0 1 .172-.07h4.888a.242.242 0 0 1 .241.242.245.245 0 0 1-.241.242v0ZM132.44 34.995h-8.699a.24.24 0 0 1-.173-.07.247.247 0 0 1-.052-.078.243.243 0 0 1 .225-.335h8.699a.248.248 0 0 1 .241.241.242.242 0 0 1-.241.242v0Z"
        stroke="#C2C2C2"
        strokeWidth={1.015}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.555 93.496h-16.836a.262.262 0 1 1 0-.525h16.836a.262.262 0 1 1 0 .525ZM128.928 98.277h-12.963a.262.262 0 0 1 0-.525h12.963a.268.268 0 0 1 .262.263.264.264 0 0 1-.262.262ZM130.734 102.892h-15.015a.263.263 0 1 1 0-.525h15.015c.069.002.135.03.184.079a.259.259 0 0 1 .001.369.259.259 0 0 1-.185.077ZM133.133 98.052h-2.399a.262.262 0 1 1 0-.525h2.388a.262.262 0 1 1 0 .525h.011ZM32.396 27.938H4.109a.263.263 0 0 1-.263-.262V4.48a.263.263 0 1 1 .525 0v22.932h28.025a.263.263 0 0 1 0 .525Z"
        fill="#C2C2C2"
      />
      <path
        d="M4.109 27.937a.257.257 0 0 1-.179-.073.251.251 0 0 1 0-.368l11.881-12.73a.285.285 0 0 1 .22-.085.273.273 0 0 1 .195.126l2.625 4.232 8.253-9.078a.263.263 0 1 1 .388.352l-8.478 9.335a.268.268 0 0 1-.22.084.252.252 0 0 1-.2-.121l-2.625-4.237-11.671 12.48a.252.252 0 0 1-.19.083ZM124.434 29.024a.251.251 0 0 1-.231-.173l-9.424-26.198L104.4 22.85a.271.271 0 0 1-.21.142.266.266 0 0 1-.231-.105L93.716 9.494l-6.463 10.358a.268.268 0 0 1-.362.084l-5.434-3.27a.263.263 0 0 1-.032-.45.263.263 0 0 1 .305.003l5.213 3.15L93.46 8.896a.273.273 0 0 1 .21-.126.247.247 0 0 1 .22.105l10.222 13.366 10.468-20.365a.273.273 0 0 1 .252-.142.279.279 0 0 1 .231.174l9.387 26.15 4.62-9.77a.269.269 0 0 1 .146-.159.26.26 0 0 1 .302.07c.024.028.042.06.053.096a.254.254 0 0 1-.028.213l-4.888 10.348a.26.26 0 0 1-.22.168Z"
        fill="#C2C2C2"
      />
      <path d="M87.027 20.97a1.286 1.286 0 1 0 0-2.572 1.286 1.286 0 0 0 0 2.573Z" fill="#fff" />
      <path
        d="M87.027 21.24a1.575 1.575 0 1 1 0-3.15 1.575 1.575 0 0 1 0 3.15Zm0-2.578a1.05 1.05 0 1 0 .001 2.102 1.05 1.05 0 0 0 0-2.102Z"
        fill="#C2C2C2"
      />
      <path d="M93.69 10.319a1.286 1.286 0 1 0 0-2.573 1.286 1.286 0 0 0 0 2.573Z" fill="#fff" />
      <path
        d="M93.69 10.582a1.575 1.575 0 1 1 0-3.15 1.575 1.575 0 0 1 0 3.15Zm0-2.573a1.05 1.05 0 1 0 1.05 1.05 1.05 1.05 0 0 0-1.05-1.034v-.016Z"
        fill="#C2C2C2"
      />
      <path d="M114.826 3.283a1.286 1.286 0 1 0 0-2.57 1.286 1.286 0 0 0 0 2.57Z" fill="#fff" />
      <path
        d="M114.821 3.546a1.576 1.576 0 1 1 0-3.152 1.576 1.576 0 0 1 0 3.152Zm0-2.572a1.047 1.047 0 0 0-.97.648 1.05 1.05 0 1 0 .97-.648Z"
        fill="#C2C2C2"
      />
      <path d="M124.428 30.033a1.286 1.286 0 1 0 .001-2.572 1.286 1.286 0 0 0-.001 2.572Z" fill="#fff" />
      <path
        d="M124.434 30.294a1.573 1.573 0 0 1-1.574-1.866 1.57 1.57 0 0 1 1.23-1.251 1.575 1.575 0 1 1 .344 3.117Zm0-2.577a1.051 1.051 0 1 0-.053 2.101 1.051 1.051 0 0 0 .053-2.101Z"
        fill="#C2C2C2"
      />
      <path d="M104.169 24.016a1.286 1.286 0 1 0 0-2.573 1.286 1.286 0 0 0 0 2.573Z" fill="#fff" />
      <path
        d="M104.169 24.3a1.576 1.576 0 0 1-.291-3.123 1.574 1.574 0 1 1 .291 3.123Zm0-2.572a1.025 1.025 0 1 0 .976.642 1.054 1.054 0 0 0-.976-.663v.02ZM39.636 7.61h-7.24a.262.262 0 1 1 0-.524h7.24a.262.262 0 1 1 0 .525ZM39.636 9.478h-7.24a.262.262 0 1 1 0-.525h7.24a.262.262 0 1 1 0 .525ZM37.2 11.353h-4.804a.262.262 0 1 1 0-.525H37.2a.268.268 0 0 1 .262.263.263.263 0 0 1-.262.262Z"
        fill="#C2C2C2"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h135v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyAnalystIcon;
