import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import ReactApexChart from 'react-apexcharts';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './stock.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronBack from 'app/component/icon/chevron-back';
import Toggle from 'react-toggle';
import axios from 'axios';
import { services } from 'app/util/const';
import Select from 'react-select';
import { ButtonComponent } from 'app/component';
import { colours } from 'app/config/constant/colours';
import _, { iteratee } from 'lodash';
import { toast } from 'react-toastify';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import { nFormatter } from 'app/util/format';

export const Stock = props => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const match = useMatch('/stock/:id');
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const [interval, setInterval] = useState<any>('daily');
  const [stock, setStock] = useState<any>('');
  const [seriesData, setDataSeries] = useState(null);
  const [dataEmiten, setDataEmiten] = useState(null);
  const [dataEmitenHeader, setDataEmitenHeader] = useState(null);

  const optionInterval = [
    {
      label: 'D',
      value: 'daily',
      label2: 'Daily',
    },
    {
      label: 'W',
      value: 'weekly',
      label2: 'Weekly',
    },
  ];

  useEffect(() => {
    setStock(match.params.id);
  }, []);

  useEffect(() => {
    getStockCandle();
    getStock(match.params.id);
  }, [stock]);

  const getStock = (search: string) => {
    axios.get(services.api.dashboard.stock + `?query=${search}`).then(res => {
      setDataEmiten(res.data.data[0]);
      getStockHeader(res.data.data[0].id);
    });
  };

  const getStockHeader = (id: string) => {
    axios.get(services.api.stock.candleHeader + `/${id}`).then(res => {
      console.log('header', res.data.data);
      setDataEmitenHeader(res.data.data);
    });
  };

  const getStockCandle = () => {
    const form = {
      interval: interval,
      stock_code: stock,
    };
    axios
      .post(services.api.stock.candleData, form)
      .then(res => {
        console.log(res.data.data);
        let data = [];
        res.data.data.map((item, index) => {
          data.push({
            x: new Date(item.id),
            y: [item.open, item.high, item.low, item.close],
          });
        });
        console.log('data', data);
        const series = [
          {
            data: data,
          },
        ];
        setDataSeries(series);
        console.log('seroes', series);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const options = {
    chart: {
      type: 'candlestick',
      height: 350,
    },
    title: {
      text: 'CandleStick Chart',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  const series = [
    {
      data: [
        {
          x: '',
          y: [6629.81, 6650.5, 6623.04, 6633.33],
        },
        {
          x: new Date(1538778600000),
          y: [6629.81, 6650.5, 6623.04, 6633.33],
        },
        {
          x: new Date(1538780400000),
          y: [6632.01, 6643.59, 6620, 6630.11],
        },
        {
          x: new Date(1538782200000),
          y: [6630.71, 6648.95, 6623.34, 6635.65],
        },
        {
          x: new Date(1538784000000),
          y: [6635.65, 6651, 6629.67, 6638.24],
        },
        {
          x: new Date(1538785800000),
          y: [6638.24, 6640, 6620, 6624.47],
        },
        {
          x: new Date(1538787600000),
          y: [6624.53, 6636.03, 6621.68, 6624.31],
        },
        {
          x: new Date(1538789400000),
          y: [6624.61, 6632.2, 6617, 6626.02],
        },
        {
          x: new Date(1538791200000),
          y: [6627, 6627.62, 6584.22, 6603.02],
        },
        {
          x: new Date(1538793000000),
          y: [6605, 6608.03, 6598.95, 6604.01],
        },
        {
          x: new Date(1538794800000),
          y: [6604.5, 6614.4, 6602.26, 6608.02],
        },
        {
          x: new Date(1538796600000),
          y: [6608.02, 6610.68, 6601.99, 6608.91],
        },
        {
          x: new Date(1538798400000),
          y: [6608.91, 6618.99, 6608.01, 6612],
        },
        {
          x: new Date(1538800200000),
          y: [6612, 6615.13, 6605.09, 6612],
        },
        {
          x: new Date(1538802000000),
          y: [6612, 6624.12, 6608.43, 6622.95],
        },
      ],
    },
  ];

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />

      <div className="container mt-5 mb-5">
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
          onClick={() => goBack()}
        >
          <ChevronBack />
          <div className="text-center">
            <h4 style={{ color: '#0A0A0A', fontWeight: 'normal', marginBottom: 0 }} className="mx-2">
              {stock}
            </h4>
            <h5>{dataEmiten?.stockName}</h5>
          </div>
          <div></div>
        </div>

        <div className="card my-4 px-3 py-3">
          <div>
            <span className="mx-2"> • Price {dataEmitenHeader?.currPrice}</span>
            <span className="mx-2"> • High {dataEmitenHeader?.highPrice}</span>
            <span className="mx-2"> • Low {dataEmitenHeader?.lowPrice}</span>
            <span className="mx-2"> • Chg {dataEmitenHeader?.fluctRate.toPrecision(2)}</span>
            <span className="mx-2"> • Avg {dataEmitenHeader?.avg}</span>
          </div>
          <div>
            <span className="mx-2"> • Vol {dataEmitenHeader?.volume}</span>
            <span className="mx-2"> • Val {nFormatter(dataEmitenHeader?.value, 2)}</span>
            <span className="mx-2"> • Trx(20) {nFormatter(dataEmitenHeader?.trx20, 2)}</span>
            <span className="mx-2"> • Mcap {nFormatter(dataEmitenHeader?.marketCap, 2)}</span>
          </div>
          <div>
            <span className="mx-2"> • R1 {dataEmitenHeader?.r1.toPrecision(6)}</span>
            <span className="mx-2"> • R2 {dataEmitenHeader?.r2.toPrecision(6)}</span>
            <span className="mx-2"> • S1 {dataEmitenHeader?.s1.toPrecision(6)}</span>
            <span className="mx-2"> • S2 {dataEmitenHeader?.s2.toPrecision(6)}</span>
          </div>
        </div>
        <div className="card">
          <ReactApexChart
            options={{
              chart: {
                type: 'candlestick',
                height: 350,
                toolbar: {
                  show: false,
                },
              },
              title: {
                // text: 'CandleStick Chart',
                // align: 'left'
              },
              xaxis: {
                type: 'datetime',
              },
              yaxis: {
                tooltip: {
                  enabled: true,
                },
              },
            }}
            series={series}
            type="candlestick"
            height={350}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Stock;
