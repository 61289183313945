import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import YouTube from 'react-youtube';
import VideoIframe from 'app/component/youtube';
import { ButtonComponent } from 'app/component';
import { jsonMap } from 'app/config/constant/map';
import './dashboard.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import WatchList from 'app/component/WatchList/WatchList';
import NewsList from 'app/component/NewsList/NewsList';
import DownloadAddHoc from 'app/component/icon/downloadaddhoc';
import CardPortfolio from 'app/component/CardPortfolio/CartPortfolio';
import axios from 'axios';
import { services } from 'app/util/const';
import { expoFormat, formatRupiah, forUpper, nFormatter } from 'app/util/format';
import Select from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';
import { delayFunction, styleChangeValue } from 'app/util/helper';
import { ShimmerButton, ShimmerBadge, ShimmerThumbnail } from 'react-shimmer-effects';
import Slider from 'react-slick';
import ReactEcharts from 'echarts-for-react';
import EmptyStockIcon from 'app/component/icon/emptystock';
import EmptyAnalystIcon from 'app/component/icon/emptyAnalyst';
import ReactPaginate from 'react-paginate';
import parse from 'html-react-parser';

export const Dashboard = () => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>(1);
  const [activeTabNews, setActiveTabNews] = useState<any>(1451);
  const [activeTabAnalys, setActiveTabAnayls] = useState<any>(1);
  const [activeTabPortfolio, setActiveTabPortfolio] = useState<any>(1);
  const [open, setOpen] = useState('');
  const [daily, setDaily] = useState<any>(null);
  const [monthly, setMonthly] = useState<any>(null);
  const [yearly, setYear] = useState<any>(null);
  const [quarter, setQuarter] = useState<any>(null);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [listStock, setListStock] = useState<any>([]);
  const [newsStock, setNewsStock] = useState<any>([]);
  const [loadingPortfolioSummary, setLoadingPortfolioSummary] = useState<any>(false);
  const [isLoadingPortfolioStock, setIsLoadingPortfolioStock] = useState<any>(false);
  const [isLoadingPortfolioMutualFund, setIsLoadingPortfolioMutualFund] = useState<any>(false);
  const [isLoadingPortfolioBond, setIsLoadingPortfolioBond] = useState<any>(false);
  const [portfolioSummary, setPortfolioSummary] = useState<any>([]);
  const [portfolioStock, setPortfolioStock] = useState<any>([]);
  const [portfolioMutualFund, setPortfolioMutualFund] = useState<any>([]);
  const [portfolioBond, setPortfolioBond] = useState<any>([]);
  const [listNews, setListNews] = useState<any>([]);
  const [dataNews, setDataNews] = useState<any>([]);
  const [payloadNews, setPayloadNews] = useState<any>({
    page: 1,
    size: 5,
  });
  const [formWatchList, setFormWatchList] = useState<any>({
    stockCode: '',
    targetPrice: null,
    isTargetBuy: true,
    expiredDate: moment(Date.now()).format('YYYY-MM-DD'),
  });
  const [formHistoryStock, setFormHistoryStock] = useState<any>({
    startDate: '',
    endDate: '',
    command: '',
    code: '',
  });

  const [formGainLossStock, setFormGainLossStock] = useState<any>({
    startDate: '',
    endDate: '',
    command: '',
    code: '',
  });

  const [historyListStock, setHistoryListStock] = useState<any>([]);
  const [historyListStockLoading, setHistoryStockLoading] = useState<any>(false);
  const [gainLossListStock, setGainLossListStock] = useState<any>([]);
  const [gainLossListStockLoading, setGainLossStockLoading] = useState<any>(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsNews = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [loadingNews, setLoadingNews] = useState<any>(false);
  const [loadingPage, setLoadingPage] = useState<any>(false);
  const [loadingNewsStock, setLoadingNewsStock] = useState<any>(false);
  const [searchReport, setSearchReport] = useState('');
  const [listReport, setListReport] = useState([]);
  const [loadingReport, setLoadingReport] = useState<any>(false);
  const [loadingStockAnayls, setLoadingAnalys] = useState<any>(false);
  const [listStockAnalys, setListStockAnayls] = useState([]);
  const [formAnalys, setFormAnayls] = useState<any>({
    indicator: 'all',
    marketIndexId: 2306,
    marketCap: 1,
    timeFrame: 'daily',
    transaction: 1000000000000,
  });
  const tabsDashboard = [
    {
      id: 1,
      name: 'Home',
    },
    {
      id: 2,
      name: 'Analys',
    },
    {
      id: 3,
      name: 'Portfolio',
    },
    {
      id: 4,
      name: 'News',
    },
  ];

  const tabsNews = [
    {
      id: 1451,
      name: 'MACRO NEWS',
    },
    {
      id: 1251,
      name: 'STOCK NEWS',
    },
    {
      id: 1051,
      name: 'FUND NEWS',
    },
    {
      id: 2801,
      name: 'BOND NEWS',
    },
  ];

  const tabsAnalys = [
    {
      id: 1,
      name: 'INDICES',
    },
    {
      id: 2,
      name: 'CURRENCIES',
    },
    {
      id: 3,
      name: 'COMMODITIES',
    },
  ];

  const renderHTML = (rawHTML: string) => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

  const getPortfolioSummary = () => {
    setLoadingPortfolioSummary(true);
    axios
      .get(services.api.portfolio.summary)
      .then(res => {
        setPortfolioSummary(res.data.data.length > 0 ? res.data.data[0] : []);
        setLoadingPortfolioSummary(false);
      })
      .catch(err => {
        setLoadingPortfolioSummary(false);
      });
  };

  const getListNews = async () => {
    await setLoadingNews(true);
    await delayFunction(1000);
    axios
      .get(services.api.news.list, {
        params: {
          categoryId: activeTabNews,
          page: payloadNews.page,
          size: payloadNews.size,
        },
      })
      .then(res => {
        console.log(res.data.data);
        setListNews(res?.data?.data?.content);
        setDataNews({
          totalPages: res?.data?.data?.totalPages,
          totalData: res?.data?.data?.totalElements,
        });
        setLoadingNews(false);
      })
      .catch(err => {
        console.log(err);
        setListNews([]);
        setLoadingNews(false);
      });
  };

  useEffect(() => {
    getListNews();
  }, [payloadNews, activeTabNews]);

  useEffect(() => {
    if (activeTabPortfolio == 1) {
      getPortfolioStock();
    } else if (activeTabPortfolio == 2) {
      getPortfolioStockMutualFund();
    } else if (activeTabPortfolio == 3) {
      getPortfolioBond();
    }
  }, [activeTabPortfolio]);

  const getPortfolioStock = async () => {
    await setIsLoadingPortfolioStock(true);
    await delayFunction(1000);
    axios
      .get(services.api.portfolio.stock)
      .then(res => {
        setPortfolioStock(res.data.data);
        setIsLoadingPortfolioStock(false);
      })
      .catch(err => {
        setPortfolioStock([]);
        setIsLoadingPortfolioStock(false);
      });
  };

  const getPortfolioStockMutualFund = async () => {
    await setIsLoadingPortfolioMutualFund(true);
    await delayFunction(1000);
    axios
      .get(services.api.portfolio.mutualfund)
      .then(res => {
        setPortfolioMutualFund(res.data.data);
        setIsLoadingPortfolioMutualFund(false);
      })
      .catch(err => {
        setPortfolioMutualFund([]);
        setIsLoadingPortfolioMutualFund(false);
      });
  };

  const getPortfolioBond = async () => {
    await setIsLoadingPortfolioBond(true);
    await delayFunction(1000);
    axios
      .get(services.api.portfolio.bond)
      .then(res => {
        setPortfolioBond(res.data.data);
        setIsLoadingPortfolioBond(false);
      })
      .catch(err => {
        setIsLoadingPortfolioBond(false);
      });
  };

  // history logic
  const getHistoryStock = () => {
    setHistoryStockLoading(true);
    axios
      .get(services.api.portfolio.historyStock, {
        params: formHistoryStock,
      })
      .then(res => {
        setHistoryStockLoading(false);
        console.log(res);
        setHistoryListStock(res.data.data.content);
      })
      .catch(err => {
        setHistoryStockLoading(false);
        setHistoryListStock([]);
      });
  };

  useEffect(() => {
    getHistoryStock();
  }, [formHistoryStock]);
  // history logic

  // gain loss logic
  const getGainLossStock = () => {
    setGainLossStockLoading(true);
    axios
      .get(services.api.portfolio.gainLossStock, {
        params: formGainLossStock,
      })
      .then(res => {
        setGainLossStockLoading(false);
        setGainLossListStock(res.data.data.content);
      })
      .catch(err => {
        setGainLossStockLoading(false);
        setGainLossListStock([]);
      });
  };

  useEffect(() => {
    getGainLossStock();
  }, [formGainLossStock]);
  // gain loss logic

  const getDailyWealth = () => {
    axios.get(services.api.dashboard.day).then(res => {
      setDaily(res.data.data);
    });
  };

  const getMonthlyWealth = () => {
    axios.get(services.api.dashboard.month).then(res => {
      setMonthly(res.data.data);
    });
  };

  const getQuarterWealth = () => {
    axios.get(services.api.dashboard.quarter).then(res => {
      setQuarter(res.data.data);
    });
  };

  const getYearWealth = () => {
    axios.get(services.api.dashboard.ytd).then(res => {
      setYear(res.data.data);
    });
  };

  const getStock = (search: string) => {
    axios.get(services.api.dashboard.stock + `?query=${search}`).then(res => {
      setListStock(res.data.data);
    });
  };

  const searchReportHandler = async () => {
    setLoadingReport(true);
    axios
      .get(services.api.analys.searchreport + `?query=${searchReport}`)
      .then(res => {
        setListReport(res.data.data);
        setLoadingReport(false);
      })
      .catch(err => {
        setListReport([]);
        setLoadingReport(false);
      });
  };

  const handlePagination = (page: any) => {
    setPayloadNews({
      page: page,
      size: 5,
    });
  };

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const initHome = async () => {
    setLoadingPage(true);
    await getDailyWealth();
    await getMonthlyWealth();
    await getQuarterWealth();
    await getYearWealth();
    await delayFunction(2000);
    setLoadingPage(false);
  };

  const getIndices = () => {
    axios.get(services.api.analys.indices).then(res => {
      setNewsStock(res.data.data);
    });
  };

  const getCurrencies = () => {
    axios.get(services.api.analys.currencies).then(res => {
      setNewsStock(res.data.data);
    });
  };

  const getComodities = () => {
    axios.get(services.api.analys.commodities).then(res => {
      setNewsStock(res.data.data);
    });
  };

  const getStockAnalys = () => {
    setLoadingAnalys(true);
    axios
      .get(services.api.analys.stockanyalys, {
        params: formAnalys,
      })
      .then(res => {
        setListStockAnayls(res.data.data.content);
        setLoadingAnalys(false);
      })
      .catch(err => {
        setListStockAnayls([]);
        setLoadingAnalys(false);
      });
  };

  const initAnalys = async (index: number) => {
    setLoadingNewsStock(true);
    if (activeTabAnalys === 1) {
      getIndices();
    } else if (activeTabAnalys === 2) {
      getCurrencies();
    } else if (activeTabAnalys === 3) {
      getComodities();
    }
    await delayFunction(2000);
    setLoadingNewsStock(false);
  };

  const initAnalysFirst = async () => {
    setLoadingPage(true);
    setLoadingNewsStock(true);
    getIndices();
    await delayFunction(2000);
    setLoadingPage(false);
    setLoadingNewsStock(false);
  };

  useEffect(() => {
    getStockAnalys();
  }, [formAnalys]);

  useEffect(() => {
    if (activeTab == 1) {
      initHome();
    } else if (activeTab == 2) {
      initAnalysFirst();
    } else if (activeTab == 3) {
      getPortfolioSummary();
    }
  }, [activeTab]);

  useEffect(() => {
    initAnalys(activeTabAnalys);
    searchReportHandler();
  }, [activeTabAnalys]);

  const onChangeFormWatchList = (value: any, name: string) => {
    setFormWatchList({ ...formWatchList, [name]: value });
  };

  const onChangeFormAnalys = (value: any, name: string) => {
    setFormAnayls({ ...formAnalys, [name]: value });
  };

  const onChangeFormHistory = (value: any, name: string) => {
    setFormHistoryStock({ ...formHistoryStock, [name]: value });
  };

  const onChangeFormGainLoss = (value: any, name: string) => {
    setFormGainLossStock({ ...formGainLossStock, [name]: value });
  };

  const createWatchList = () => {
    axios
      .post(services.api.dashboard.createwatchlist, formWatchList)
      .then(res => {
        toast.success('Successfully Create Watchlist');
        setFormWatchList({
          stockCode: '',
          targetPrice: null,
          isTargetBuy: true,
          expiredDate: moment(Date.now()).format('YYYY-MM-DD'),
        });
      })
      .catch(err => {
        toast.error('Failed Create Watchlist');
      });
  };

  const downloadAdhocdocument = () => {
    axios
      .get(
        services.api.report.addhoc,
        { responseType: 'blob' } // !!!
      )
      .then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'addhoc.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const htmlDecode = input => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />
      <div style={{ borderBottom: '1px solid #bdbdbd' }} className="bg-white">
        <div className="container">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {tabsDashboard.map((item, index) => {
              return (
                <div
                  className={activeTab == item.id ? 'mx-3 active-tabs py-2' : 'mx-3 default-tabs py-2'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPayloadNews({
                      page: 1,
                      size: 5,
                    });
                    setActiveTab(item.id);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {activeTab === 1 && (
        <div className="bg-white pb-5 pt-5">
          <div className="container ">
            {!loadingPage ? (
              <div>
                <p className="text-center">Total Wealth Value</p>
                <div style={{ width: 250 }} className="mx-auto">
                  <span style={{ fontSize: 12, position: 'absolute' }}>Rp</span>
                  <h3 className="text-center">
                    {'   '}
                    {expoFormat(daily?.total)}
                  </h3>
                </div>
                <div
                  style={{ color: colours.primaryOrange, cursor: 'pointer' }}
                  className="text-center"
                  onClick={() => downloadAdhocdocument()}
                >
                  <DownloadAddHoc /> Request for Ad-hoc Statement
                </div>
              </div>
            ) : (
              <div className="row">
                <div style={{ width: 350 }} className="mx-auto">
                  <ShimmerThumbnail height={15} width={300} rounded />
                  <ShimmerThumbnail height={30} width={300} rounded />
                  <ShimmerThumbnail height={15} width={300} rounded />
                </div>
              </div>
            )}
          </div>

          <div className="my-5 mx-auto" style={{ width: '90%' }}>
            <div>
              {!loadingPage ? (
                <h5>
                  <span className="fw-bold">Portfolio</span> <span style={{ fontWeight: 'lighter' }}>Performance</span>
                </h5>
              ) : (
                <ShimmerThumbnail height={30} width={200} rounded />
              )}
            </div>

            <div className="my-3">
              {!loadingPage ? (
                <div className="row">
                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 my-2">
                    <CardPortfolio
                      title={'Daily'}
                      type={'basic'}
                      backgroundColor={'#EA6D26'}
                      value={nFormatter(daily?.total, 2)}
                      percentDiff={daily?.percent ? parseInt(daily?.percent).toFixed(2) : '0.0'}
                      changediff={daily?.changes ? nFormatter(daily?.changes, 3) : '0.0'}
                      status={daily?.status}
                    />
                  </div>

                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  my-2">
                    <CardPortfolio
                      title={'Month To Date'}
                      type={'basic'}
                      backgroundColor={'#EA6D26'}
                      value={nFormatter(monthly?.total, 2)}
                      percentDiff={monthly?.percent ? parseInt(monthly?.percent).toFixed(2) : '0.0'}
                      changediff={monthly?.changes ? nFormatter(monthly?.changes, 3) : '0.0'}
                      status={monthly?.status}
                    />
                  </div>

                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  my-2">
                    <CardPortfolio
                      title={'Quarter to date'}
                      type={'special'}
                      backgroundColor={'#002F48'}
                      value={nFormatter(quarter?.total, 2)}
                      percentDiff={quarter?.percent ? parseInt(quarter?.percent).toFixed(2) : '0.0'}
                      changediff={quarter?.changes ? nFormatter(quarter?.changes, 3) : '0.0'}
                      status={quarter?.status}
                    />
                  </div>

                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  my-2">
                    <CardPortfolio
                      title={'Quarter to date'}
                      type={'special'}
                      backgroundColor={'#002F48'}
                      value={nFormatter(yearly?.total, 2)}
                      percentDiff={yearly?.percent ? parseInt(yearly?.percent).toFixed(2) : '0.0'}
                      changediff={yearly?.changes ? nFormatter(yearly?.changes, 3) : '0.0'}
                      status={yearly?.status}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style={{ marginRight: 15 }}>
                    <ShimmerThumbnail height={150} width={300} rounded />
                  </div>
                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style={{ marginRight: 15 }}>
                    <ShimmerThumbnail height={150} width={300} rounded />
                  </div>
                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style={{ marginRight: 15 }}>
                    <ShimmerThumbnail height={150} width={300} rounded />
                  </div>
                  <div className="col-sm-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style={{ marginRight: 15 }}>
                    <ShimmerThumbnail height={150} width={300} rounded />
                  </div>
                </div>
              )}
            </div>

            <div className="row my-3">
              <h5 className="my-4">Watchlist</h5>
              <div className="col-lg-8">
                <div>
                  <Label for="examplePassword">Stock Picker</Label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Search Stock"
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={listStock}
                    onInputChange={e => getStock(e)}
                    getOptionValue={option => `${option['stockCode']}`}
                    getOptionLabel={option => `${option['stockCode']}`}
                    onChange={(e: any) => onChangeFormWatchList(e?.stockCode, 'stockCode')}
                  />
                </div>
                <div className="row my-3">
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Target Buy/Sell</Label>
                      <Input
                        type="select"
                        name="select"
                        value={formWatchList.isTargetBuy == true ? 'Buy' : 'Sell'}
                        placeholder="text"
                        onChange={e => onChangeFormWatchList(e.target.value == 'Buy' ? true : false, 'isTargetBuy')}
                      >
                        <option>Buy</option>
                        <option>Sell</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <Label for="examplePassword">Target Price</Label>
                      <Input
                        type="text"
                        name="select"
                        value={formWatchList.targetPrice}
                        placeholder="Rp 0"
                        onChange={e => onChangeFormWatchList(e.target.value.replace(/\D/g, ''), 'targetPrice')}
                      ></Input>
                    </FormGroup>
                  </div>

                  <div>
                    <FormGroup>
                      <Label for="examplePassword">Expiry Date Notification</Label>
                      <Input
                        type="date"
                        name="select"
                        placeholder="text"
                        onChange={e => onChangeFormWatchList(e.target.value, 'expiredDate')}
                      ></Input>
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-xl-4 col-sm-12 col-12 my-2">
                    <ButtonComponent
                      text={'Add Watchlist'}
                      onPress={() => createWatchList()}
                      wrapperClass={''}
                      outline={true}
                      buttonStyle={{
                        backgroundColor: colours.primaryOrange,
                        borderColor: colours.primaryOrange,
                        padding: '8px 2px',
                        borderRadius: 0,
                      }}
                      textStyle={{
                        color: colours.white,
                        fontSiz: 12,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      )}

      {activeTab === 2 && (
        <div className="bg-white pb-5 pt-5">
          <div className="my-5 mx-auto" style={{ width: '90%' }}>
            <div>
              <h5>
                <span className="fw-bold">World Indices</span>
              </h5>
            </div>

            <div className="my-3 px-2">
              <div className="row px-0">
                {tabsAnalys.map((item, index) => {
                  if (!loadingPage) {
                    return (
                      <div
                        className={
                          activeTabAnalys == item.id
                            ? 'active-tab-anayls px-4 py-3 text-center col-12 my-2'
                            : 'default-tab-analys px-3 py-3 text-center col-12 my-2'
                        }
                        style={{ cursor: 'pointer', marginRight: '3%' }}
                        onClick={() => setActiveTabAnayls(item.id)}
                      >
                        {item.name}
                      </div>
                    );
                  } else {
                    return (
                      <div className="default-tab-analys-loading px-3 py-3 text-center col-12 my-2" style={{ marginRight: '3%' }}>
                        <ShimmerThumbnail height={80} width={200} rounded />
                      </div>
                    );
                  }
                })}
              </div>

              <div className="my-3">
                {!loadingNewsStock ? (
                  <Slider {...settings}>
                    {newsStock.map((item, index) => {
                      return (
                        <div style={{ border: '1px solid #F4F4F4', background: '#bdbdbd', minWidth: 150 }}>
                          <div style={{ border: '1px solid #F4F4F4', minWidth: 150 }} className="py-3 px-3 mx-2">
                            <div className="mb-5">
                              {activeTabAnalys === 1 ? (
                                <h5 style={{ fontSize: 16 }}> {item?.name}</h5>
                              ) : activeTabAnalys == 2 ? (
                                <h5 style={{ fontSize: 16 }}> {item?.currencyFromTo}</h5>
                              ) : activeTabAnalys === 3 ? (
                                <h5 style={{ fontSize: 16 }}> {item?.name}</h5>
                              ) : (
                                <></>
                              )}
                            </div>

                            <p style={{ marginBottom: 4, fontSize: 16 }}>{item?.lastValue}</p>
                            <span style={{ fontSize: 14 }} className={styleChangeValue(item)}>
                              {item?.valueChange} ({item?.percentageChange})
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  <>
                    <div className="row">
                      <div className="default-tab-analys-loading px-3 py-3 text-center col-12 my-2" style={{ marginRight: '5%' }}>
                        <ShimmerThumbnail height={150} width={200} rounded />
                      </div>
                      <div className="default-tab-analys-loading px-3 py-3 text-center col-12 my-2" style={{ marginRight: '5%' }}>
                        <ShimmerThumbnail height={150} width={200} rounded />
                      </div>
                      <div className="default-tab-analys-loading px-3 py-3 text-center col-12 my-2" style={{ marginRight: '5%' }}>
                        <ShimmerThumbnail height={150} width={200} rounded />
                      </div>
                      <div className="default-tab-analys-loading px-3 py-3 text-center col-12 my-2" style={{ marginRight: '5%' }}>
                        <ShimmerThumbnail height={150} width={200} rounded />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/*  */}

            <h5 style={{ fontSize: 18, marginTop: '4%' }} className="fw-bold">
              Analys
            </h5>
            <div className="row my-4">
              <div className="col-lg-6 col-xl-6 col-12 col-sm-12 col-xs-12 my-4">
                <h5 style={{ fontSize: 16, marginBottom: '3%' }} className="">
                  Technical Analys
                </h5>
                <div>
                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <Input
                          type="text"
                          name="text"
                          id="examplePassword"
                          placeholder="Search Stock"
                          onFocus={() => navigate('/search-stock')}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                          Technical Indicator
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          value={formAnalys.indicator}
                          id="exampleSelect"
                          placeholder="text"
                          onChange={e => onChangeFormAnalys(e.target.value, 'indicator')}
                        >
                          <option value="all">All</option>
                          <option value="bear">Bear</option>
                          <option value="bull">Bull</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <FormGroup>
                        <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                          Market Cap
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          value={formAnalys.marketCap}
                          id="exampleSelect"
                          placeholder="text"
                          onChange={e => onChangeFormAnalys(e.target.value, 'marketCap')}
                        >
                          <option value="1000000000000">1 Trilion</option>
                          <option value="100000000000000">100 Trilion</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <div className="col-6">
                      <FormGroup>
                        <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                          Transaction
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          value={formAnalys.transaction}
                          id="exampleSelect"
                          placeholder="text"
                          onChange={e => onChangeFormAnalys(e.target.value, 'transaction')}
                        >
                          <option value="10000000000">10 Billion</option>
                          <option value="100000000000">100 Billion</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <FormGroup>
                        <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                          Time Frame
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          value={formAnalys.timeFrame}
                          id="exampleSelect"
                          placeholder="text"
                          onChange={e => onChangeFormAnalys(e.target.value, 'timeFrame')}
                        >
                          <option value="value">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                        </Input>
                      </FormGroup>
                    </div>
                    <div className="col-6">
                      <FormGroup>
                        <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                          Index
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          value={formAnalys.marketIndexId}
                          id="exampleSelect"
                          placeholder="text"
                          onChange={e => onChangeFormAnalys(e.target.value, 'marketIndexId')}
                        >
                          <option value={2306}>Composite</option>
                          <option value={2306}>IDX30</option>
                          <option value={2306}>IDXBASIC</option>
                          <option value={2306}>IDXCYLIC</option>
                          <option value={2306}>IDXENERGY</option>
                          <option value={2306}>IDXHEALTH</option>
                        </Input>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="card bg-white" style={{ border: 'none' }}>
                  <table>
                    <thead>
                      <tr>
                        <td className="py-3 px-2" style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>
                          Stock
                        </td>
                        <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Current Price</td>
                        <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Resistance</td>
                        <td style={{ borderBottom: '1px solid #E0E0E0', color: '#9E9E9E' }}>Support</td>
                      </tr>
                    </thead>
                    <tbody>
                      {!loadingStockAnayls &&
                        listStockAnalys.length > 0 &&
                        listStockAnalys.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td className="px-2 py-4">{item?.stockCode}</td>
                                <td>
                                  <div className={styleChangeValue(item)}>{item?.currPrice}</div>
                                  <div className={styleChangeValue(item)}>
                                    {item?.fluctAmount} ({item?.fluctRate})
                                  </div>
                                </td>
                                <td>
                                  <div>R1 {item?.r1}</div>
                                  <div>R1 {item?.r2}</div>
                                </td>
                                <td>
                                  <div>S1 {item?.s1}</div>
                                  <div>S1 {item?.s2}</div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                {loadingStockAnayls && (
                  <>
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                  </>
                )}
              </div>
              <div className="col-lg-6 col-xl-6 col-12 col-sm-12 col-xs-12 my-4">
                <h5 style={{ fontSize: 16, marginBottom: '3%' }}>Research Report</h5>
                <FormGroup>
                  <Input
                    type="text"
                    name="search_report"
                    value={searchReport}
                    placeholder="Search"
                    onChange={e => setSearchReport(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        searchReportHandler();
                      }
                    }}
                  ></Input>
                </FormGroup>

                {!loadingReport &&
                  listReport.length > 0 &&
                  listReport.map((item, index) => {
                    return (
                      <>
                        <div style={{ border: '1px solid #E0E0E0', cursor: 'pointer' }} className="my-2 py-3 px-3">
                          <p style={{ color: '#9E9E9E', fontSize: 12 }}>
                            {item?.publishDate ? moment(item?.publishDate).format('DD/MM/YY') : '-'}
                          </p>
                          <h5 style={{ color: '#404040', fontSize: 14 }}>{item?.reportTitle}</h5>
                        </div>
                      </>
                    );
                  })}
                {!loadingReport && listReport.length == 0 && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 300,
                        alignSelf: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Please fill search form and press enter
                    </div>
                  </div>
                )}
                {loadingReport && (
                  <>
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                    <ShimmerThumbnail height={120} width={'100%'} rounded />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 3 && (
        <div className="bg-white pb-5 pt-5">
          <div className="my-5 mx-auto" style={{ width: '90%' }}>
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                <div>
                  <p className="text-center">Total Wealth Value</p>
                  <div style={{ width: 250 }} className="mx-auto">
                    <span style={{ fontSize: 12, position: 'absolute' }}>Rp</span>
                    <h3 className="text-center">
                      {'   '}
                      {expoFormat(portfolioSummary?.Total)}
                    </h3>
                  </div>
                </div>
                <div>
                  {portfolioSummary.Chart?.length > 0 && (
                    <ReactEcharts
                      option={{
                        tooltip: {
                          trigger: 'item',
                        },
                        series: [
                          {
                            data: [
                              { value: portfolioSummary?.Chart[0].total, name: portfolioSummary?.Chart[0].title },
                              { value: portfolioSummary?.Chart[1].total, name: portfolioSummary?.Chart[1].title },
                              { value: portfolioSummary?.Chart[2].total, name: portfolioSummary?.Chart[2].title },
                              { value: portfolioSummary?.Chart[3].total, name: portfolioSummary?.Chart[3].title },
                            ],
                            type: 'pie',
                            radius: '50%',
                            color: ['#F37F26', '#002F48', '#9E9E9E', '#043B72'],
                          },
                        ],
                      }}
                    />
                  )}
                </div>

                <div className="my-3">
                  {portfolioSummary.Chart?.length > 0 &&
                    portfolioSummary.Chart?.map((data, index) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              if (index != 0) {
                                setActiveTabPortfolio(index);
                              }
                            }}
                            className={activeTabPortfolio === index ? 'card px-3 py-3 my-3 active-portfolio' : 'card px-3 py-3 my-3'}
                            key={`${data.title} - ${index}`}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                              <div style={{ color: colours.greyText }}>
                                {forUpper(data?.title)} ( {data?.persentase?.toFixed(2)}%)
                              </div>
                              <div>Rp. {data?.total ? expoFormat(data?.total) : 0}</div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                {activeTabPortfolio === 1 && (
                  <>
                    {/*  */}
                    <div className="my-3">
                      <h5 style={{ fontSize: 16, marginBottom: '1%' }} className="">
                        LAST
                      </h5>
                      {isLoadingPortfolioStock ? (
                        <div className="my-3">
                          <ShimmerThumbnail height={80} rounded />
                          <ShimmerThumbnail height={80} rounded />
                          <ShimmerThumbnail height={80} rounded />
                        </div>
                      ) : (
                        <> </>
                      )}
                      {!isLoadingPortfolioStock && portfolioStock.length > 0 ? (
                        !isLoadingPortfolioStock &&
                        portfolioStock.map((data, index) => {
                          return (
                            <>
                              <div
                                className="card px-3 py-1 my-3 responsive-card-stock"
                                key={`- ${index}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/stock/${data.itemCode}`)}
                              >
                                <table>
                                  <tr style={{}}>
                                    <td style={{ width: '15%' }}>{data.itemCode}</td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Value
                                      </div>
                                      <div>{data.balanceValue}</div>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Pts(+/-)
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsValue}
                                      </div>
                                    </td>
                                    <td style={{ width: '10%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        %
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsPercentage?.toFixed(2)}
                                      </div>
                                    </td>
                                    <td style={{ width: '15%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Lot
                                      </div>
                                      <div>{data?.lot}</div>
                                    </td>
                                    <td>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Last
                                      </div>
                                      <div>{data?.lastPrice}</div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </>
                          );
                        })
                      ) : !isLoadingPortfolioStock ? (
                        <div className="py-3 text-center">
                          <EmptyAnalystIcon />
                          <h5 style={{ fontSize: 14 }} className="my-5">
                            Empty Stock
                          </h5>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="my-3">
                      <h5 style={{ fontSize: 16, marginBottom: '1%' }} className="">
                        History
                      </h5>
                      <div className="row">
                        <div className="col-12">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Stock Picker
                          </Label>
                          <div>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Search Stock"
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={listStock}
                              onInputChange={e => getStock(e)}
                              getOptionValue={option => `${option['stockCode']}`}
                              getOptionLabel={option => `${option['stockCode']}`}
                              onChange={(e: any) => {
                                onChangeFormHistory(e?.stockCode, 'code');
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Start Date
                          </Label>
                          <FormGroup>
                            <Input
                              type="date"
                              name="text"
                              id="examplePassword"
                              placeholder="Start date"
                              onChange={e => {
                                onChangeFormHistory(moment(e.target.value).format('YYYYMMDD'), 'startDate');
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            End Date
                          </Label>
                          <FormGroup>
                            <Input
                              type="date"
                              name="text"
                              id="examplePassword"
                              placeholder="End Date"
                              onChange={e => {
                                onChangeFormHistory(moment(e.target.value).format('YYYYMMDD'), 'endDate');
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Command
                          </Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="text"
                              id="examplePassword"
                              placeholder="Command"
                              onChange={e => {
                                onChangeFormHistory(e.target.value, 'command');
                              }}
                            >
                              <option value={''}>All</option>
                              <option value={0}>Buy</option>
                              <option value={1}>Sell</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                      {historyListStockLoading ? (
                        <>
                          <div className="my-3">
                            <ShimmerThumbnail height={80} rounded />
                            <ShimmerThumbnail height={80} rounded />
                            <ShimmerThumbnail height={80} rounded />
                          </div>
                        </>
                      ) : historyListStock.length > 0 ? (
                        historyListStock.map((data, index) => {
                          return (
                            <>
                              <div
                                className="card px-3 py-1 my-3 responsive-card-stock"
                                key={`- ${index}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/stock/${data.itemCode}`)}
                              >
                                <table>
                                  <tr style={{}}>
                                    <td style={{ width: '15%' }}>{data.itemCode}</td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Value
                                      </div>
                                      <div>{data.balanceValue}</div>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Pts(+/-)
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsValue}
                                      </div>
                                    </td>
                                    <td style={{ width: '10%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        %
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsPercentage?.toFixed(2)}
                                      </div>
                                    </td>
                                    <td style={{ width: '15%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Lot
                                      </div>
                                      <div>{data?.lot}</div>
                                    </td>
                                    <td>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Last
                                      </div>
                                      <div>{data?.lastPrice}</div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="py-3 text-center">
                          <EmptyAnalystIcon />
                          <h5 style={{ fontSize: 14 }} className="my-5">
                            Empty Stock
                          </h5>
                        </div>
                      )}
                    </div>
                    {/*  */}

                    {/*  */}
                    <div className="my-3">
                      <h5 style={{ fontSize: 16, marginBottom: '1%' }} className="">
                        GAIN / LOSS
                      </h5>
                      <div className="row">
                        <div className="col-12">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Stock Picker
                          </Label>
                          <FormGroup>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              placeholder="Search Stock"
                              isDisabled={isDisabled}
                              isLoading={isLoading}
                              isClearable={isClearable}
                              isRtl={isRtl}
                              isSearchable={isSearchable}
                              name="color"
                              options={listStock}
                              onInputChange={e => getStock(e)}
                              getOptionValue={option => `${option['stockCode']}`}
                              getOptionLabel={option => `${option['stockCode']}`}
                              onChange={(e: any) => {
                                onChangeFormGainLoss(e?.stockCode, 'code');
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Start Date
                          </Label>
                          <FormGroup>
                            <Input
                              type="date"
                              name="text"
                              id="examplePassword"
                              placeholder="Start date"
                              onChange={e => {
                                onChangeFormGainLoss(moment(e.target.value).format('YYYYMMDD'), 'startDate');
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            End Date
                          </Label>
                          <FormGroup>
                            <Input
                              type="date"
                              name="text"
                              id="examplePassword"
                              placeholder="End Date"
                              onChange={e => {
                                onChangeFormGainLoss(moment(e.target.value).format('YYYYMMDD'), 'endDate');
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-12 col-lg-4">
                          <Label for="examplePassword" style={{ color: '#8E8E8E', fontSize: 14 }}>
                            Command
                          </Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="text"
                              id="examplePassword"
                              placeholder="Command"
                              onChange={e => {
                                onChangeFormGainLoss(e.target.value, 'command');
                              }}
                            >
                              <option value={''}>All</option>
                              <option value={0}>Buy</option>
                              <option value={1}>Sell</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </div>
                      {gainLossListStockLoading ? (
                        <>
                          <div className="my-3">
                            <ShimmerThumbnail height={80} rounded />
                            <ShimmerThumbnail height={80} rounded />
                            <ShimmerThumbnail height={80} rounded />
                          </div>
                        </>
                      ) : gainLossListStock.length > 0 ? (
                        gainLossListStock.map((data, index) => {
                          return (
                            <>
                              <div
                                className="card px-3 py-1 my-3 responsive-card-stock"
                                key={`- ${index}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/stock/${data.itemCode}`)}
                              >
                                <table>
                                  <tr style={{}}>
                                    <td style={{ width: '15%' }}>{data.itemCode}</td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Value
                                      </div>
                                      <div>{data.balanceValue}</div>
                                    </td>
                                    <td style={{ width: '20%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Pts(+/-)
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsValue}
                                      </div>
                                    </td>
                                    <td style={{ width: '10%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        %
                                      </div>
                                      <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                                        {data?.ptsPercentage?.toFixed(2)}
                                      </div>
                                    </td>
                                    <td style={{ width: '15%' }}>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Lot
                                      </div>
                                      <div>{data?.lot}</div>
                                    </td>
                                    <td>
                                      <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                        Last
                                      </div>
                                      <div>{data?.lastPrice}</div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="py-3 text-center">
                          <EmptyAnalystIcon />
                          <h5 style={{ fontSize: 14 }} className="my-5">
                            Empty Stock
                          </h5>
                        </div>
                      )}
                    </div>

                    {/*  */}
                  </>
                )}

                {activeTabPortfolio === 2 && (
                  <>
                    {isLoadingPortfolioMutualFund ? (
                      <div className="my-3">
                        <ShimmerThumbnail height={80} rounded />
                        <ShimmerThumbnail height={80} rounded />
                        <ShimmerThumbnail height={80} rounded />
                      </div>
                    ) : (
                      <>
                        <div className="my-3">
                          {portfolioMutualFund.length > 0 ? (
                            portfolioMutualFund.map((data, index) => {
                              return (
                                <>
                                  <div className="card px-3 py-1 my-3" key={`- ${index}`} style={{ cursor: 'pointer' }}>
                                    <table>
                                      <tr>
                                        <td>{data.itemCode}</td>
                                        <td>
                                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                            Value
                                          </div>
                                          <div>{data.balanceValue}</div>
                                        </td>
                                        <td>
                                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                            Pts(+/-)
                                          </div>
                                          <div>{data?.ptsValue}</div>
                                        </td>
                                        <td>
                                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                            %
                                          </div>
                                          <div>{data?.ptsPercentage?.toFixed(2)}</div>
                                        </td>
                                        <td>
                                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                            Lot
                                          </div>
                                          <div>{data?.lot}</div>
                                        </td>
                                        <td>
                                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                                            Last
                                          </div>
                                          <div>{data?.lastPrice}</div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="py-3 text-center">
                              <EmptyAnalystIcon />
                              <h5 style={{ fontSize: 14 }} className="my-5">
                                Empty Mutual Fund
                              </h5>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/*  */}

                    {/*  */}
                  </>
                )}

                {/*  */}
                {activeTabPortfolio === 3 && (
                  <>
                    {isLoadingPortfolioBond ? (
                      <div className="my-3">
                        <ShimmerThumbnail height={80} rounded />
                        <ShimmerThumbnail height={80} rounded />
                        <ShimmerThumbnail height={80} rounded />
                      </div>
                    ) : (
                      <>
                        <div className="my-3">
                          {portfolioBond.length > 0 ? (
                            portfolioBond.map((data, index) => {
                              return (
                                <>
                                  <div className="card px-3 py-1 my-3" key={`- ${index}`} style={{ cursor: 'pointer' }}>
                                    <table>
                                      <tr>
                                        <td style={{ width: '80%' }}>
                                          <div className="my-2">
                                            <h5 style={{ color: '#0A0A0A', fontSize: 14 }}>{data?.itemCode}</h5>
                                            <p style={{ color: '#616161', fontSize: 12, marginBottom: 0 }}>{data?.itemName}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="my-2 " style={{ float: 'right' }}>
                                            <h5 style={{ color: '#0A0A0A', fontSize: 14 }}>Rp. {expoFormat(data?.balanceValue)}</h5>
                                            <p
                                              style={
                                                data?.ptsValue[0] != '-'
                                                  ? { color: '#43936C', fontSize: 12, marginBottom: 0 }
                                                  : { color: '#CB3A31', fontSize: 12, marginBottom: 0 }
                                              }
                                            >
                                              {data?.ptsValue[0] != '-' ? '+' : ''}
                                              {expoFormat(data?.ptsValue)} ( {data?.ptsValue[0] != '-' ? '+' : ''}
                                              {data?.ptsPercentage.toFixed(2)})
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="py-3 text-center">
                              <EmptyAnalystIcon />
                              <h5 style={{ fontSize: 14 }} className="my-5">
                                Empty Mutual Fund
                              </h5>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/*  */}

                    {/*  */}
                  </>
                )}
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      )}

      {activeTab === 4 && (
        <div className="bg-white pb-5 pt-5">
          <div className="container">
            <h4 style={{ fontWeight: 'normal' }}>
              <span style={{ fontWeight: 'bold' }}>Market</span> News
            </h4>

            <div className="px-2 col-md-6 col-lg-6 col-xl-6 col-12 col-sm-12 col-xs-12">
              <Slider {...settingsNews}>
                {tabsNews.map((item, index) => {
                  return (
                    <div style={{ border: '1px solid #F4F4F4', background: '#bdbdbd' }}>
                      <div className="py-3 px-3 mx-2">
                        <p
                          className="text-center pb-2 pt-2"
                          style={
                            activeTabNews === item.id
                              ? {
                                  marginBottom: 4,
                                  fontSize: 14,
                                  borderBottom: '2px solid #EA6D26',
                                  color: '#EA6D26',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                }
                              : {
                                  marginBottom: 4,
                                  fontSize: 14,
                                  color: '#757575',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                }
                          }
                          onClick={() => setActiveTabNews(item?.id)}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            {!loadingNews &&
              listNews.length > 0 &&
              listNews.map((item, index) => {
                return (
                  <>
                    <div
                      key={`news-${item.id}-${item.title}}`}
                      className="card my-2 px-2 py-2"
                      style={{ cursor: 'pointer' }}
                      onClick={(data: any) => {
                        {
                          navigate(`/news/${item.id}`);
                          // console.log(item.id)
                        }
                      }}
                    >
                      <div className="row">
                        <div className="col-5 col-md-2">
                          <img
                            src={'/services/newsservice/api/v1/news/img/article/' + item?.imageUrl}
                            alt=""
                            height="150"
                            width="100%"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=';
                            }}
                          />
                        </div>
                        <div className="col-7 col-md-10">
                          <h5>{item?.title}</h5>
                          <div dangerouslySetInnerHTML={{ __html: htmlDecode(item.shortDescription) }} />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            {loadingNews && (
              <>
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
              </>
            )}

            <div>
              <ReactPaginate
                breakLabel="..."
                // nextLabel="next >"
                onPageChange={e => handlePagination(e.selected)}
                pageCount={dataNews.totalPages}
                // previousLabel="< previous"
                renderOnZeroPageCount={null}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination flex-wrap'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Dashboard;
