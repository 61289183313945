import React, { useEffect } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import ProfitArrow from '../icon/profitarrow';
import DownArrow from '../icon/downArrow';

interface HeroProps {
  title?: string;
  backgroundColor?: string;
  value?: any;
  diffValue?: number;
  percentDiff?: any;
  positif?: boolean;
  textColor?: string;
  textColorDiff?: string;
  changediff?: any;
  status?: any;
  type?: any;
}

export const CardPortfolio = ({
  title,
  backgroundColor = '#EA6D26',
  value,
  diffValue,
  percentDiff,
  positif,
  textColor = '#FFF',
  textColorDiff = '#fff',
  changediff,
  status,
  type = 'basic',
}: HeroProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div className="px-3 py-3" style={{ backgroundColor: backgroundColor }}>
      <div className="">
        <div className="" style={{ color: textColor, fontSize: 12 }}>
          {title}
        </div>

        <div className="row mt-1">
          <div className="col-1">
            <span style={{ position: 'absolute', fontSize: 13, color: textColor }}>Rp</span>
          </div>
          <div className="col">
            <h3 style={{ color: textColor }} className="my-1">
              {value}
              <span className="mx-1">{status === 'up' ? <ProfitArrow /> : <DownArrow />}</span>
            </h3>
          </div>
        </div>

        {type == 'basic' ? (
          <div className="text-center mt-1">
            <span style={{ color: textColorDiff, fontSize: 13 }}>
              {changediff ?? 0} ( {percentDiff ?? 0}% )
            </span>
          </div>
        ) : (
          <div className="text-center mt-1">
            <span style={status === 'up' ? { color: '#3BDB63', fontSize: 13 } : { color: '#FF0000', fontSize: 13 }}>
              {changediff ?? 0} ( {percentDiff ?? 0}% )
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardPortfolio;
