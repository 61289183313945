import * as React from 'react';
import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M20 .8C9.396.8.8 9.396.8 20S9.396 39.2 20 39.2 39.2 30.604 39.2 20 30.604.8 20 .8Zm7.81 15.728c.008.164.01.328.01.488 0 5-3.802 10.762-10.758 10.762a10.67 10.67 0 0 1-5.796-1.7c.294.036.596.05.902.05a7.574 7.574 0 0 0 4.696-1.618 3.79 3.79 0 0 1-3.532-2.624 3.8 3.8 0 0 0 1.706-.066 3.784 3.784 0 0 1-3.034-3.708v-.046c.51.282 1.094.454 1.714.474a3.78 3.78 0 0 1-1.17-5.052 10.752 10.752 0 0 0 7.794 3.954 3.782 3.782 0 0 1 6.444-3.45 7.593 7.593 0 0 0 2.4-.918 3.8 3.8 0 0 1-1.662 2.094 7.597 7.597 0 0 0 2.172-.598 7.666 7.666 0 0 1-1.886 1.958Z"
        fill="#002F48"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default TwitterIcon;
