import './home.scss';

import React, { useState } from 'react';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  NavLink,
} from 'reactstrap';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import YouTube from 'react-youtube';
import VideoIframe from 'app/component/youtube';
import { ButtonComponent } from 'app/component';
import { jsonMap } from 'app/config/constant/map';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import { Hero } from 'app/component/Hero';
import About from 'app/component/About';
import Feature from 'app/component/Feature';
import Application from 'app/component/application';
const geoUrl = 'https://raw.githubusercontent.com/deldersveld/topojson/master/world-continents.json';
const markers = [
  {
    markerOffset: -30,
    name: 'Mongolia',
    coordinates: [99.3480128, 46.7849449],
  },
  {
    markerOffset: -30,
    name: 'United Kingdom',
    coordinates: [-4.5697081, 52.8203996],
  },
  {
    markerOffset: -30,
    name: 'China',
    coordinates: [95.1490624, 35.4630963],
  },
  {
    markerOffset: -30,
    name: 'Hongkong',
    coordinates: [113.9876178, 22.3529808],
  },
  {
    markerOffset: -30,
    name: 'India',
    coordinates: [73.7377845, 20.7715787],
  },
  {
    markerOffset: -30,
    name: 'South Korea',
    coordinates: [125.6305972, 35.8026765],
  },
  {
    markerOffset: -30,
    name: 'Japan',
    coordinates: [128.1608558, 33.1932492],
  },
  {
    markerOffset: -30,
    name: 'Vietnam',
    coordinates: [101.4174437, 5.7583935],
  },
  {
    markerOffset: -30,
    name: 'Singapura',
    coordinates: [103.7007267, 1.3141676],
  },
  {
    markerOffset: -30,
    name: 'Indonesia',
    coordinates: [106.865036, -6.17511],
  },
  {
    markerOffset: -30,
    name: 'Australia',
    coordinates: [133.775131, -25.274399],
  },
  {
    markerOffset: -30,
    name: 'Canada',
    coordinates: [-106.346771, 56.130367],
  },
  {
    markerOffset: -30,
    name: 'USA',
    coordinates: [-74.1197622, 40.6976637],
  },
  {
    markerOffset: -30,
    name: 'Colombia',
    coordinates: [-74.8328272, 0.6420935],
  },
  {
    markerOffset: -30,
    name: 'Brazil',
    coordinates: [-42.33773, -18.2163614],
  },
];

export const Home = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const account = useAppSelector(state => state.authentication.account);
  const [activeTab, setActiveTab] = useState<any>(1);
  const [open, setOpen] = useState('');
  const toggleSet = (id: any) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const goToLogin = () => {
    navigate('login');
  };
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />
      <Hero title={'Timeless Investment, Growth, and Beyond'} />

      {/*  */}
      <About />
      {/*  */}
      <Feature />

      {/*  */}
      <Application />

      {/*  */}
      <div
        className="mt-sm"
        style={{
          backgroundColor: 'linear-gradient(180deg, rgba(229, 230, 229, 0.5) 0%, rgba(229, 230, 229, 0) 100%)',
          paddingBottom: '5%',
        }}
      >
        <div className="container-custom">
          <div className="dots-hero-global">
            <img src="content/images/dots.webp" alt="Logo" style={{ height: 100 }} />
          </div>
          <div className="dots-hero-map">
            <img src="content/images/dots.webp" alt="Logo" style={{ height: 100 }} />
          </div>
          <h1 className="fw-bold my-3">Global Expansion</h1>
          <p>Mirae Asset strives for safe and diversified asset allocation for our client’s secure future</p>

          <div className="my-5">
            <Row>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>17</div>
                        <div className="mx-2">Years</div>
                        <br></br>
                      </div>
                      <div>Global Expansion</div>
                    </div>

                    <div id="box">
                      <div id="borderLeft"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>15</div>
                        <div className="mx-2"></div>
                        <br></br>
                      </div>
                      <div>Global Market</div>
                    </div>

                    <div id="box">
                      <div id="borderLeft"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>32</div>
                        <div className="mx-2"></div>
                        <br></br>
                      </div>
                      <div>Office Worldwide</div>
                    </div>

                    <div id="box">
                      <div id="borderLeft"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>18</div>
                        <div className="mx-2">Th</div>
                        <br></br>
                      </div>
                      <div>GLobal ETF Manager by AUM</div>
                    </div>

                    <div id="box">
                      <div id="borderLeft"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>26</div>
                        <div className="mx-2"></div>
                        <br></br>
                      </div>
                      <div>Countries With Clients</div>
                    </div>

                    <div id="box">
                      <div id="borderLeft"></div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="d-flex">
                    <div>
                      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <div style={{ fontSize: 36 }}>1690</div>
                        <div className="mx-2"></div>
                        <br></br>
                      </div>
                      <div style={{ fontSize: 15 }}>Product Distributed Globally</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* <ComposableMap>
            <Geographies geography={geoUrl}>
              {({ geographies }) => geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />)}
            </Geographies>
            {markers.map(({ name, coordinates, markerOffset }) => (
              <Marker key={name} coordinates={coordinates}>
                <g
                  fill="none"
                  stroke="#FF5533"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(-12, -24)"
                >
                  <circle cx="12" cy="10" r="3" />
                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
                <text textAnchor="middle" y={markerOffset} style={{ fontFamily: 'system-ui', fill: '#5D5A6D' }}>
                  {name}
                </text>
              </Marker>
            ))}
          </ComposableMap> */}
          <div className="text-center mt-5 mb-3">
            <img src="content/images/map-new.webp" alt="Logo" style={{ width: '100%' }} />
          </div>
        </div>
      </div>

      {/*  */}
      <div
        style={{
          backgroundColor: colours.primaryNavy,
          paddingTop: '5%',
          paddingBottom: '5%',
          borderBottomColor: colours.primaryOrange,
          borderBottomWidth: 6,
          borderStyle: 'solid',
        }}
      >
        <div className="container-custom">
          <div className="my-2">
            <h1 className="fw-bold my-3 text-white">Common questions</h1>
          </div>
          <div>
            <div id="home">
              <UncontrolledAccordion flush open={open} stayOpen={true}>
                <AccordionItem className="accordion-qna">
                  <AccordionHeader targetId="1" onClick={() => toggleSet('1')} className="px-3">
                    What do I need to qualify to be a Sage Club member?{' '}
                  </AccordionHeader>
                  <AccordionBody accordionId="1" className="accordion-body-qna">
                    An average beginning day balance of 3 Bio rupiah or more in any combination of investment products. There is no Monthly
                    Service Fee for Sage Club Member.
                  </AccordionBody>
                </AccordionItem>
                <br />

                <AccordionItem className="accordion-qna">
                  <AccordionHeader targetId="2" onClick={() => toggleSet('2')} className="px-3">
                    What are some key benefits of being a Sage Club member?{' '}
                  </AccordionHeader>
                  <AccordionBody accordionId="2" className="accordion-body-qna">
                    Sage Club members receive priority service, personalized investment guidance from Mirae Asset Priority Wealth
                    management, exclusive events & benefits, and the support of a team of dedicated financial advisors.
                  </AccordionBody>
                </AccordionItem>
                <br />
                <AccordionItem className="accordion-qna">
                  <AccordionHeader targetId="3" onClick={() => toggleSet('3')} className="px-3" style={{}}>
                    {' '}
                    What are the products being offered at Sage Club?{' '}
                  </AccordionHeader>
                  <AccordionBody accordionId="3" className="accordion-body-qna">
                    Stocks, Mutual Funds, Bonds, KPD & ETF
                  </AccordionBody>
                </AccordionItem>
                <br />
                <AccordionItem className="accordion-qna">
                  <AccordionHeader targetId="4" onClick={() => toggleSet('4')} className="px-3">
                    {' '}
                    How to join Sage Club?{' '}
                  </AccordionHeader>
                  <AccordionBody accordionId="4" className="accordion-body-qna">
                    <NavLink tag={Link} to="/" className="btn btn-primary btn-open-account">
                      Open Account Mirae Asset
                    </NavLink>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      {/*  */}
      {/* <div
        style={{
          backgroundColor: 'linear-gradient(180deg, rgba(229, 230, 229, 0.5) 0%, rgba(229, 230, 229, 0) 100%)',
          paddingTop: '5%',
          paddingBottom: '5%',
        }}
      >
        <div className="container">
          <h1 className="fw-bold my-3 text-center">Start invest today</h1>
          <Row className="text-center justify-content-center mt-5">
            <Col xs={12} sm={12} xl={6} lg={6}>
              <Row>
                <Col xs={12} md={8} lg={8} className="">
                  <Input placeholder="Email/Phone Number" style={{ padding: '14px 10px' }} />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <ButtonComponent
                    text={'Get Started'}
                    onPress={() => alert('text')}
                    wrapperClass={''}
                    outline={true}
                    buttonStyle={{
                      backgroundColor: colours.primaryOrange,
                      borderColor: colours.primaryOrange,
                      padding: '14px 10px',
                    }}
                    textStyle={{
                      color: colours.white,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div> */}
      {/*  */}
      <Footer />
    </>
  );
};

export default Home;
