import React, { useEffect, useState } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import VideoIframe from '../youtube';

const tabsData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

interface HeroProps {
  title?: string;
}

export const Application = ({ title }: HeroProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  const [activeTab, setActiveTab] = useState<any>(1);

  return (
    <div
      style={{
        backgroundColor: colours.primaryNavy,
        paddingTop: '5%',
        paddingBottom: '3%',
      }}
    >
      <div className="container-custom">
        <div className="my-2">
          <Row>
            <Col lg={8} xl={8} xs={12} sm={12} style={{ alignSelf: 'center' }}>
              <div>
                <h1 className="fw-bold my-3 text-white font-size-title-sage">SAGE CLUB Mobile Apps</h1>
                <h5 className="text-white mt-4 text-description-app">
                  is a complimentary service provided to the members of Priority Wealth Management. This service allows registered members
                  to monitor their portfolio information as of the daily close of business. Portfolio information includes real-time account
                  and portfolio performances. In addition, Members of Sage Club are also able to view our researches, technical analysis,
                  watchlist, and market news update.
                </h5>
                <div className="d-flex mt-5">
                  <div style={{ width: 170 }} className="mr-5">
                    <a href="https://play.google.com/store/apps/details?id=id.co.miraeasset.sageclub">
                      <img src="content/images/google-play.webp" className="image-style-base" alt="Logo" />
                    </a>
                  </div>
                  <div style={{ width: 170, marginLeft: '3%' }}>
                    <a href="https://apps.apple.com/au/app/sage-club/id1589182679">
                      <img src="content/images/app-store.webp" className="image-style-base" alt="Logo" />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} xl={4} xs={12} sm={12} className="">
              <div className="my-5 image-application">
                <img src="content/images/pwm-mobile-app.webp" className="image-style-base" alt="Logo" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Application;
