import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import ReactApexChart from 'react-apexcharts';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { ShimmerButton, ShimmerBadge, ShimmerThumbnail } from 'react-shimmer-effects';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './search-stock.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import ChevronBack from 'app/component/icon/chevron-back';
import Select from 'react-select';
import axios from 'axios';
import { services } from 'app/util/const';
import moment from 'moment';
import EmptyAnalystIcon from 'app/component/icon/emptyAnalyst';

export const SearchStock = props => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const match = useMatch('/stock/:id');
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const [interval, setInterval] = useState<any>('daily');
  const [stock, setStock] = useState<any>('');
  const [seriesData, setDataSeries] = useState(null);
  const [dataEmiten, setDataEmiten] = useState(null);
  const [dataEmitenHeader, setDataEmitenHeader] = useState(null);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [listStock, setListStock] = useState<any>([]);
  const [formWatchList, setFormWatchList] = useState<any>({
    stockCode: '',
    targetPrice: null,
    isTargetBuy: true,
    expiredDate: moment(Date.now()).format('YYYY-MM-DD'),
  });
  const [isStockLoading, setIsStockLoading] = useState(false);
  const optionInterval = [
    {
      label: 'D',
      value: 'daily',
      label2: 'Daily',
    },
    {
      label: 'W',
      value: 'weekly',
      label2: 'Weekly',
    },
  ];

  const goBack = () => {
    navigate(-1);
  };

  const getStock = (search: string) => {
    axios.get(services.api.dashboard.stock + `?query=${search}`).then(res => {
      console.log(res.data.data);
      setListStock(res.data.data);
    });
  };

  const onChangeFormWatchList = (value: any, name: string) => {
    setFormWatchList({ ...formWatchList, [name]: value });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />

      <div className="container mt-5 mb-5">
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
          onClick={() => goBack()}
        >
          <ChevronBack />
          <div className="text-center">
            <h4 style={{ color: '#0A0A0A', fontWeight: 'normal', marginBottom: 0 }} className="mx-2">
              {stock}
            </h4>
            <h5>Search Stock</h5>
          </div>
          <div></div>
        </div>

        <div>
          <Label for="examplePassword">Stock Picker</Label>
          <FormGroup>
            <Input type="text" name="text" id="examplePassword" placeholder="Search Stock" onChange={e => getStock(e.target.value)} />
          </FormGroup>
        </div>

        <div>
          {isStockLoading ? (
            <>
              <div className="my-3">
                <ShimmerThumbnail height={80} rounded />
                <ShimmerThumbnail height={80} rounded />
                <ShimmerThumbnail height={80} rounded />
              </div>
            </>
          ) : listStock.length > 0 ? (
            listStock.map((data, index) => {
              return (
                <>
                  <div
                    className="card px-3 py-1 my-3 responsive-card-stock"
                    key={`- ${index}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/stock/${data.itemCode}`)}
                  >
                    <table>
                      <tr style={{}}>
                        <td style={{ width: '15%' }}>{data.itemCode}</td>
                        <td style={{ width: '20%' }}>
                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                            Value
                          </div>
                          <div>{data.balanceValue}</div>
                        </td>
                        <td style={{ width: '20%' }}>
                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                            Pts(+/-)
                          </div>
                          <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>{data?.ptsValue}</div>
                        </td>
                        <td style={{ width: '10%' }}>
                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                            %
                          </div>
                          <div style={data?.ptsValue[0] == '-' ? { color: '#3BDB63' } : { color: '#D8382E' }}>
                            {data?.ptsPercentage?.toFixed(2)}
                          </div>
                        </td>
                        <td style={{ width: '15%' }}>
                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                            Lot
                          </div>
                          <div>{data?.lot}</div>
                        </td>
                        <td>
                          <div style={{ color: '#9E9E9E', fontSize: 14 }} className="my-2">
                            Last
                          </div>
                          <div>{data?.lastPrice}</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </>
              );
            })
          ) : (
            <div className="py-3 text-center">
              <EmptyAnalystIcon />
              <h5 style={{ fontSize: 14 }} className="my-5">
                Empty Stock
              </h5>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchStock;
