import React, { useEffect } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import ProfitArrow from '../icon/profitarrow';

export const WatchList = (data: any) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div className="py-3">
      <div className="row">
        <div className="col-6" style={{ alignSelf: 'center' }}>
          {data.data.emiten}
        </div>
        <div className="col-3">
          <div>
            <p style={{ fontSize: 13, color: '#9E9E9E' }}>Target Buy</p>
            <span style={{ color: '#3BDB63' }}>{data.data.target_buy}</span>
          </div>
        </div>
        <div className="col-3">
          <div>
            <p style={{ fontSize: 13, color: '#9E9E9E' }}>Last Price</p>
            <span style={{ color: '#404040' }}>{data.data.last_price}</span>
          </div>
        </div>
      </div>
      <hr className="mt-4" />
    </div>
  );
};

export default WatchList;
