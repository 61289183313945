import React, { useEffect } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';

interface HeroProps {
  title?: string;
}

export const Hero = ({ title }: HeroProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div>
      <div className="splash_img">
        <div className="hero-orange">
          <div className="title-hero">{title}</div>
          <div className="dots-hero">
            <img src="content/images/dots.webp" alt="Logo" style={{ height: 100 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
