import * as React from 'react';
import { SVGProps } from 'react';

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M26 20a6 6 0 1 1-12 0c0-.342.036-.676.098-1H12v7.994c0 .556.45 1.006 1.006 1.006h13.99A1.006 1.006 0 0 0 28 26.994V19h-2.098c.062.324.098.658.098 1Zm-6 4a4 4 0 1 0-.002-8A4 4 0 0 0 20 24Zm4.8-8.2h2.398a.602.602 0 0 0 .602-.6v-2.398a.602.602 0 0 0-.602-.602H24.8a.602.602 0 0 0-.602.602V15.2c.002.33.272.6.602.6ZM20 .8a19.2 19.2 0 1 0 0 38.4A19.2 19.2 0 0 0 20 .8Zm10 26.978C30 29 29 30 27.778 30H12.222C11 30 10 29 10 27.778V12.222C10 11 11 10 12.222 10h15.556C29 10 30 11 30 12.222v15.556Z"
        fill="#002F48"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default InstagramIcon;
