import * as React from 'react';

const SyncIcon = props => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2 1.6a.8.8 0 0 1 .8.8v1.68a5.602 5.602 0 0 1 9.281 2.052.8.8 0 1 1-1.508.534A4.002 4.002 0 0 0 4.799 5.6h2.4a.8.8 0 1 1 0 1.6h-4a.8.8 0 0 1-.8-.8v-4a.8.8 0 0 1 .8-.8Zm.006 7.245a.8.8 0 0 1 1.021.488A4.002 4.002 0 0 0 11.2 10.4H8.8a.8.8 0 0 1 0-1.6h4a.8.8 0 0 1 .8.8v4a.8.8 0 0 1-1.6 0v-1.681a5.602 5.602 0 0 1-9.281-2.052.8.8 0 0 1 .487-1.022Z"
      fill="#F5F5F5"
    />
  </svg>
);

export default SyncIcon;
