import * as React from 'react';
import { SVGProps } from 'react';

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="m23.206 19.666-4.492-2.096c-.392-.182-.714.022-.714.456v3.948c0 .434.322.638.714.456l4.49-2.096c.394-.184.394-.484.002-.668ZM20 .8C9.396.8.8 9.396.8 20S9.396 39.2 20 39.2 39.2 30.604 39.2 20 30.604.8 20 .8Zm0 27c-9.828 0-10-.886-10-7.8s.172-7.8 10-7.8 10 .886 10 7.8-.172 7.8-10 7.8Z"
        fill="#002F48"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default YoutubeIcon;
