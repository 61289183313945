import React, { useEffect, useState } from 'react';
import './index.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import { text } from '@fortawesome/fontawesome-svg-core';
import VideoIframe from '../youtube';

const tabsData = [
  {
    id: 1,
    label: 'Wealth Management',
  },
  {
    id: 2,
    label: 'Business Advisory',
  },
  {
    id: 3,
    label: 'Base Value',
  },
  {
    id: 4,
    label: 'Your Team',
  },
];

interface HeroProps {
  title?: string;
}

export const Feature = ({ title }: HeroProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  const [activeTab, setActiveTab] = useState<any>(1);

  return (
    <div
      style={{
        backgroundColor: 'linear-gradient(180deg, rgba(229, 230, 229, 0.5) 0%, rgba(229, 230, 229, 0) 100%)',
        paddingTop: '5%',
        paddingBottom: '5%',
      }}
    >
      <div className="container-custom">
        <h1 className="fw-bold my-3">Explore Our Community Insights</h1>
        <p>Your success defines our success. See how our global network of experts have put their abilities to work.</p>

        <div className="my-5 tab-style-feature">
          {tabsData &&
            tabsData.map(item => (
              <div className={item.id === activeTab ? 'tab-active' : 'tab-base'} onClick={() => setActiveTab(item.id)} key={item.id}>
                {item.label}
              </div>
            ))}
        </div>
        <div className="my-5">
          <Row>
            <Col lg={4} xl={4} xs={12} sm={12}>
              <div style={{ height: '280px' }}>
                {activeTab === 1 ? (
                  <img src="content/images/banner-hero.webp" className="image-style" alt="Logo" />
                ) : activeTab === 2 ? (
                  <img src="content/images/tab2.webp" className="image-style" alt="Logo" />
                ) : activeTab === 3 ? (
                  <img src="content/images/tab3.webp" className="image-style" alt="Logo" />
                ) : (
                  <img src="content/images/tab4.webp" className="image-style" alt="Logo" />
                )}
              </div>
            </Col>
            <Col lg={8} xl={8} xs={12} sm={12}>
              <div>
                {activeTab === 1 ? (
                  <>
                    <div className="text-description">
                      With extraordinary wealth comes exceptional complexity. We recognize that increasingly complex and significant
                      financial decisions magnify your need for quality advice. It is a privilege to advise Indonesia's most influential
                      people and institutions. Mirae Asset Sage Club Priority Wealth Management combines the vast resources of a trusted
                      global financial services firm with the individualized services of an investment boutique for a hands-on, tailored
                      approach. A profoundly personal approach goes beyond wealth and investment management – it is a lifelong partnership
                      for growth. Our dedicated team of wealth management advisors will create a dynamic road map aligned to your unique
                      priorities and future goals. Join us for access to expertise, opportunity, and beyond.
                    </div>
                  </>
                ) : activeTab === 2 ? (
                  <div className="parent-list-tab">
                    <div>
                      <h5 className="title-tab2">Investment solutions</h5>
                      <p className="text-tab-2">
                        Offers up-to-date investment insight and implements it in a powerful and wise asset allocation plan.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Portfolio Management</h5>
                      <p className="text-tab-2">Provides decentralized portfolios to meet the needs of our clients, wherever they are.</p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Insights</h5>
                      <p className="text-tab-2">
                        Access reliable news and research on markets, trends, and opportunities to make better investment decisions.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Specialized Services</h5>
                      <p className="text-tab-2">Stock, Mutual Fund, Bonds, KPD & ETF, Pre IPO Access.</p>
                    </div>
                  </div>
                ) : activeTab === 3 ? (
                  <div className="parent-list-tab">
                    <div>
                      <h5 className="title-tab2">Client First</h5>
                      <p className="text-tab-2">
                        Our client's success is essential for us since their success is our success, too. We build long-term relationships
                        with our clients, benefiting from our proven investment strategy.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Objectivity</h5>
                      <p className="text-tab-2">
                        We assess every investment opportunity fairly and are not in favor. Objectivity makes us recognizable and helps
                        ensure that our decision in-tune with our client's needs.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Teamwork</h5>
                      <p className="text-tab-2">
                        Mirae Asset's success is built on respect for everyone. We aim to create a true meritocracy where talent and
                        achievement are rewarded, and opportunity is equally available to all.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Citizenship</h5>
                      <p className="text-tab-2">
                        In Mirae Asset, we are fully aware of our responsibility. As good citizens, we aspire to give back to the community
                        where we live and actively support and participate in the initiatives.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="parent-list-tab">
                    <div>
                      <h5 className="title-tab2">Relatioship Manager</h5>
                      <p className="text-tab-2">Provides exclusive services, including portfolio management, and financial advice</p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Special Dealer</h5>
                      <p className="text-tab-2">
                        Always ready to provide a solution for each transaction so that you have a profitable trading strategy.
                      </p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Research Analyst</h5>
                      <p className="text-tab-2">Inform you of market opportunities with curated research and analysis.</p>
                    </div>
                    <div>
                      <h5 className="title-tab2">Customer Services</h5>
                      <p className="text-tab-2">
                        We always welcome suggestions to serve you better. Tell the Customer Service who is ready to listen to you and
                        provide responsive solutions.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Feature;
