import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import FacebookIcon from 'app/component/icon/facebook';
import LinkedInIcon from 'app/component/icon/linkedin';
import TwitterIcon from 'app/component/icon/twitter';
import YouTube from 'react-youtube';
import YoutubeIcon from 'app/component/icon/youtube';
import InstagramIcon from 'app/component/icon/instagram';

const Footer = () => (
  <div className="bg-white">
    <div className="footer-2-1 mx-auto position-relative p-0" style={{ fontFamily: '"Poppins", sans-serif' }}>
      <div className="list-footer">
        <div className="row gap-md-0 gap-3">
          <div className="col-lg-3 col-md-6">
            <div className="">
              <div className="list-space mb-4">
                <img src="content/images/sage-footer-logo.webp" alt="Logo" style={{ width: 300 }} />
              </div>
              <span className="fw-bold">Mirae Asset Sekuritas | Member of Indonesia Stock Exchange</span>
              <br></br>
              <span>
                Pacific Century Place, Ground Floor Unit GF3, SCBD Lot 10 Jl.Jend Sudirman Kav. 52-53. Jakarta Selatan. +62 21 395 25010
              </span>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <h2 className="footer-text-title">Our Product</h2>
            <nav className="list-unstyled">
              <li className="list-space">
                <a href="" className="list-menu">
                  Capital Market
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  NAVI by Mirae Asset
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  Invesment Banking
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  ETF
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  Story
                </a>
              </li>
            </nav>
          </div>
          <div className="col-lg-2 col-md-6">
            <h2 className="footer-text-title">Legal</h2>
            <nav className="list-unstyled">
              <li className="list-space">
                <a href="" className="list-menu">
                  Privacy Policy
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  Terms & Condition
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  Risk Warning
                </a>
              </li>
            </nav>
          </div>
          <div className="col-lg-2 col-md-6">
            <h2 className="footer-text-title">Company</h2>
            <nav className="list-unstyled">
              <li className="list-space">
                <a href="" className="list-menu">
                  About Us
                </a>
              </li>
              <li className="list-space">
                <a href="" className="list-menu">
                  Security
                </a>
              </li>
            </nav>
          </div>
          <div className="col-lg-3 col-md-6">
            <h1 className="footer-text-title">Connect With Us</h1>
            <div className="d-flex title-font font-medium align-items-center gap-4 mt-4">
              <a href="">
                <FacebookIcon />
              </a>
              <a href="">
                <LinkedInIcon />
              </a>
              <a href="">
                <TwitterIcon />
              </a>
              <a href="">
                <YoutubeIcon />
              </a>
              <a href="">
                <InstagramIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="border-color info-footer">
        <div className="">
          <hr className="hr" />
        </div>
        <div className="text-center my-3">© 2022 PT Mirae Asset Sekuritas Indonesia. All Rights Reserved</div>
      </div>
    </div>
  </div>
);

export default Footer;
