import * as React from 'react';

const DocumentDownloadIcon = props => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 1.6a1.6 1.6 0 0 0-1.6 1.6v9.6a1.6 1.6 0 0 0 1.6 1.6h6.4a1.6 1.6 0 0 0 1.6-1.6V5.93a1.6 1.6 0 0 0-.468-1.131L9.6 2.068A1.6 1.6 0 0 0 8.47 1.6H4.8Zm4 4.8a.8.8 0 0 0-1.6 0v2.868L6.166 8.234a.8.8 0 1 0-1.131 1.131l2.4 2.4a.8.8 0 0 0 1.13 0l2.4-2.4a.8.8 0 0 0-1.13-1.131L8.8 9.268V6.4Z"
      fill="#F5F5F5"
    />
  </svg>
);

export default DocumentDownloadIcon;
