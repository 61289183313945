import * as React from 'react';

const DownloadAddHoc = props => (
  <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1Zm3.293-7.707a1 1 0 0 1 1.414 0L9.5 10.586V3a1 1 0 1 1 2 0v7.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414Z"
      fill="#F37F26"
    />
  </svg>
);

export default DownloadAddHoc;
