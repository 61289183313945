import './header.scss';

import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, BrandIcon } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { ButtonComponent } from 'app/component';
import { colours } from 'app/config/constant/colours';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearAuthentication, logout, logoutSession } from 'app/shared/reducers/authentication';
import ChevronDownIcon from 'app/component/icon/chevron-down';
import SyncIcon from 'app/component/icon/sync-icon';
import DocumentDownloadIcon from 'app/component/icon/document-download';
import WhatsappIcon from 'app/component/icon/wa-icon';
import EmailOutlineIcon from 'app/component/icon/email-icon-outline';
import InterfaceSettingIcon from 'app/component/icon/interface-setting';
import InterfaceLogoutIcon from 'app/component/icon/interface-logout';
import axios from 'axios';
import { services } from 'app/util/const';
import NotifIcon from 'app/component/icon/notif-icon';
import EmptyStockIcon from 'app/component/icon/emptystock';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const account = useAppSelector(state => state.authentication.account);
  const [toogleUser, setToogleUser] = useState<any>(false);
  const [toogleNotif, setToogleNotif] = useState<any>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [listNotif, setListNotif] = useState<any>([]);
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  useEffect(() => {
    getListNotif();
  }, []);

  const isSticky = e => {
    const header = document.querySelector('.header-top');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const goToLogin = () => {
    navigate('login');
  };

  const goToDashboard = () => {
    navigate('login');
  };
  const toogleUserHandler = () => {
    setToogleUser(!toogleUser);
  };

  const toogleNotifHandler = () => {
    setToogleNotif(!toogleNotif);
  };

  const handleLogOut = () => {
    dispatch(logout());
    // window.location.href = '/'
  };

  const downloadTaxReport = () => {
    axios
      .get(
        services.api.report.yearlytax,
        { responseType: 'blob' } // !!!
      )
      .then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'taxreport.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const getListNotif = () => {
    axios.get(services.api.notification.list).then(response => {
      console.log(response.data.data);
      setListNotif(response.data.data);
    });
  };

  const openWa = (number: any) => {
    window.open(`http://wa.me/${number}`, '_blank', 'noopener,noreferrer');
  };

  const openEmail = (email: any) => {
    window.open(`mailto:${email}`, '_blank', 'noopener,noreferrer');
  };

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  return (
    <div id="app-header" style={{ background: '#fff', boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)' }} className="header-top">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" light expand="md" className="jh-navbar container">
        <Brand />
        <div style={{ position: 'absolute', top: 15, left: 15 }}>
          <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        </div>

        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar style={{ minHeight: 100, alignContent: 'center', alignItems: 'center' }}>
            <Home />
            {/* {props.isAuthenticated && <EntitiesMenu />} */}
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            {props.isAuthenticated ? (
              <>
                <Dropdown isOpen={toogleNotif} toggle={() => toogleNotifHandler()} className="px-2" size="md">
                  <DropdownToggle
                    tag="div"
                    style={{ background: 'transparent', border: 'none', color: '#404040', cursor: 'pointer' }}
                    data-toggle="test"
                  >
                    <FontAwesomeIcon icon="bell" style={{ height: 22, color: '#BCBEBC' }} />
                    <div
                      style={{
                        position: 'absolute',
                        height: 16,
                        width: 16,
                        borderRadius: '50%',
                        top: -8,
                        right: 0,
                        background: '#EA6D26',
                        color: '#fff',
                        fontSize: 10,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      {listNotif.length > 100 ? '99+' : listNotif.length}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="mt-3" style={{ minWidth: 280 }}>
                    <div>
                      {listNotif.length > 0 ? (
                        <>
                          {listNotif.map((item, index) => {
                            return (
                              <>
                                <div className="px-2" style={{ cursor: 'pointer' }}>
                                  <h5 style={{ fontSize: 16 }}>{item?.title}</h5>
                                  <p style={{ fontSize: 14, color: '#9E9E9E' }}>{item?.description}</p>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div style={{ width: 280, height: 300, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                            <div>No notifications</div>
                          </div>
                        </>
                      )}
                    </div>
                  </DropdownMenu>
                </Dropdown>
                <img
                  className="mx-1"
                  src={''}
                  alt="Avatar"
                  style={{ height: 24, width: 24, borderRadius: '50%' }}
                  onError={({ currentTarget }) => {
                    currentTarget.src = 'https://www.pngkey.com/png/detail/115-1150152_default-profile-picture-avatar-png-green.png';
                  }}
                />

                <Dropdown isOpen={toogleUser} toggle={() => toogleUserHandler()} className="px-2" size="md">
                  <DropdownToggle tag="div" style={{ background: 'transparent', border: 'none', color: '#404040', cursor: 'pointer' }}>
                    {account?.data?.accountName} <ChevronDownIcon />
                  </DropdownToggle>
                  <DropdownMenu className="mt-3" style={{ minWidth: 280 }}>
                    <div className="px-3">
                      <a style={{ textDecoration: 'none', color: '#404040', fontSize: 15 }} href="/dashboard">
                        Dashboard
                      </a>
                    </div>
                    <hr />
                    <div className="px-3">
                      <p style={{ color: '#9E9E9E', fontSize: 14, marginBottom: 10 }}>Yearly Tax Report</p>
                      <Button style={{ background: '#002F48', color: '#fff' }} onClick={() => downloadTaxReport()}>
                        <DocumentDownloadIcon /> Download
                      </Button>
                    </div>
                    <hr />
                    <div className="px-3">
                      <p style={{ color: '#9E9E9E', fontSize: 14, marginBottom: 10 }}>Risk Profile</p>
                      <Button
                        style={{ background: '#F37F26', color: '#fff', borderColor: '#F37F26' }}
                        onClick={() => navigate('risk-profile')}
                      >
                        <SyncIcon /> Analyze
                      </Button>
                      <span className="ml-3"> {account?.data?.customerRiskGrade}</span>
                    </div>
                    <hr />
                    <div className="px-3">
                      <p style={{ color: '#9E9E9E', fontSize: 14, marginBottom: 5 }}>Relationship Manager</p>
                      <p style={{ color: '#404040', fontSize: 15, marginBottom: 10, fontWeight: 'bold' }}>
                        {account.data?.relationshipManager?.name}
                      </p>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                          style={{ background: '#002F48', color: '#fff', borderColor: '#002F48', marginRight: 10 }}
                          onClick={() => openWa(account.data?.relationshipManager?.mobileNo)}
                        >
                          <WhatsappIcon /> Whatsapp
                        </Button>
                        <Button
                          style={{ background: '#002F48', color: '#fff', borderColor: '#002F48' }}
                          onClick={() => openEmail(account.data?.relationshipManager?.email)}
                        >
                          <EmailOutlineIcon /> Email
                        </Button>
                      </div>
                    </div>
                    <hr />
                    <DropdownItem>
                      <a style={{ textDecoration: 'none', color: '#404040', fontSize: 15 }} href="/profile">
                        <InterfaceSettingIcon style={{ marginRight: 5 }} /> Profile
                      </a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <a style={{ textDecoration: 'none', color: '#404040', fontSize: 15 }} href="/setting">
                        <InterfaceSettingIcon style={{ marginRight: 5 }} /> Setting
                      </a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => handleLogOut()}
                      style={{ textDecoration: 'none', color: '#404040', fontSize: 15, fontWeight: 'bold' }}
                    >
                      <InterfaceLogoutIcon style={{ marginRight: 5 }} /> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </>
            ) : (
              <>
                <ButtonComponent
                  text={'Login'}
                  onPress={() => goToLogin()}
                  wrapperClass={'mx-3'}
                  outline={true}
                  buttonStyle={{
                    backgroundColor: colours.black,
                    borderColor: colours.black,
                    borderRadius: 0,
                  }}
                  textStyle={{
                    color: colours.white,
                  }}
                />
                <ButtonComponent
                  text={'Request An Introductifon'}
                  disabled={false}
                  outline={true}
                  buttonStyle={{ borderColor: colours.primaryOrange, borderRadius: 0 }}
                  textStyle={{
                    color: colours.primaryOrange,
                  }}
                  buttonClassname={'button-info'}
                />
              </>
            )}
            {/* <AccountMenu isAuthenticated={props.isAuthenticated} /> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
