export const delayFunction = delayInms => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
};

export const styleChangeValue = (item: any) => {
  if (!item?.percentageChange) return '';
  if (item?.percentageChange[0] === '+') {
    return 'text-succes-stock';
  } else if (item?.percentageChange[0] === '-') {
    return 'text-danger-stock';
  } else {
    return '';
  }
};
