import './login.scss';

import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert, Input, Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { colours } from 'app/config/constant/colours';
import YouTube from 'react-youtube';
import VideoIframe from 'app/component/youtube';
import { ButtonComponent } from 'app/component';
import { jsonMap } from 'app/config/constant/map';
import { login } from 'app/shared/reducers/authentication';

export const LoginTwo = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const [showModal, setShowModal] = useState(showModalLogin);
  const navigate = useNavigate();
  const location = useLocation();

  const [form, setForm] = useState<any>({
    username: '',
    password: '',
    grant_type: 'password',
    rememberMe: false,
  });

  const onChangeValue = (value: any, name: any) => {
    let formTemp = form;
    formTemp[name] = value;
    setForm(formTemp);
  };

  const handleLogin = () => dispatch(login(form.username, form.password, form.rememberMe, form.grant_type));
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }
  return (
    <>
      <div style={{ height: '100vh', alignItems: 'center', display: 'flex' }}>
        <div className="w-100">
          <Row className="">
            <Col
              style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center' }}
              sm={12}
              xs={12}
              md={6}
              xl={6}
              xxl={6}
            >
              <div style={{ textAlign: 'center' }} className="img-show">
                <img src="content/images/login-illustration.webp" alt="Logo" style={{ width: 600 }} />
              </div>
            </Col>
            <Col
              sm={12}
              xs={12}
              md={6}
              xl={6}
              xxl={6}
              style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: 'center' }}
            >
              <div className="container">
                <div className="">
                  <div
                    className="mx-auto-login"
                    style={{
                      borderWidth: 100,
                      backgroundColor: 'rgba(229, 230, 229, 0.55)',
                      borderRadius: 24,
                      borderColor: 'linear-gradient(139.09deg, rgba(224, 224, 224, 0.5) -15.6%, rgba(255, 255, 255, 0.5) 108.82%)',
                    }}
                  >
                    <div className="py-3">
                      <div className="text-center mt-5 mb-3">
                        <img src="content/images/logo-sage-club-login.webp" alt="Logo" className="style-logo-login" />
                      </div>
                      <div className="pt-3">
                        <p className="text-center " style={{ fontSize: 18, fontWeight: 100 }}>
                          Welcome to Mirae Asset SAGE CLUB<br></br>
                          Sign in to continue.
                        </p>
                      </div>

                      <div className="px-4 py-3">
                        <Input
                          placeholder="Username"
                          className="my-3"
                          style={{ padding: '14px 10px' }}
                          onChange={e => onChangeValue(e.target.value, 'username')}
                        />
                        <Input
                          placeholder="Password"
                          className="my-3"
                          type="password"
                          style={{ padding: '14px 10px' }}
                          onChange={e => onChangeValue(e.target.value, 'password')}
                        />
                        <div className="my-4 text-title">
                          <Input
                            placeholder="Password"
                            checked={form.rememberMe}
                            className=""
                            type="checkbox"
                            onChange={e => onChangeValue(e.target.value, 'rememberMe')}
                          />{' '}
                          Remember Me
                          <a
                            href="/forgot-password"
                            style={{ color: colours.primaryOrange, textDecoration: 'none', fontWeight: 500, float: 'right' }}
                          >
                            {' '}
                            Forgot Password?
                          </a>
                        </div>
                        <ButtonComponent
                          text={'Login'}
                          onPress={() => handleLogin()}
                          wrapperClass={''}
                          outline={true}
                          buttonStyle={{
                            backgroundColor: colours.primaryOrange,
                            borderColor: colours.primaryOrange,
                            padding: '14px 10px',
                          }}
                          textStyle={{
                            color: colours.white,
                          }}
                        />

                        <p className="my-4" style={{ fontWeight: 100, textAlign: 'center' }}>
                          Don't have account?{' '}
                          <a href="/register" style={{ color: colours.primaryOrange, textDecoration: 'none', fontWeight: 500 }}>
                            Register Now
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default LoginTwo;
