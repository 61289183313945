import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button, Spinner } from 'reactstrap';
import { Translate } from 'react-jhipster';

import { useAppDispatch, useAppSelector } from 'app/config/store';

interface buttonProps {
  text: string;
  onPress?: () => void;
  textColor?: string;
  textStyle?: any;
  buttonStyle?: ButtonStyle;
  disabled?: boolean;
  buttonType?: 'default' | 'outline' | 'outline-danger' | 'warning' | 'no-border' | 'solid';
  isLoading?: boolean;
  outline?: boolean;
  wrapperClass?: any;
  buttonClassname?: any;
}

export interface ButtonStyle {
  width?: number;
  height?: number;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
  padding?: string;
  backgroundColor?: string;
  shadow?: boolean;
}
export const ButtonComponent = ({
  text,
  onPress,
  textColor,
  textStyle,
  buttonStyle,
  disabled = false,
  buttonType,
  isLoading,
  outline,
  wrapperClass,
  buttonClassname,
}: buttonProps) => {
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  return (
    <div className={wrapperClass}>
      <Button
        color="light"
        block={true}
        disabled={disabled}
        style={buttonStyle}
        outline={outline}
        onClick={onPress}
        active={disabled}
        className={buttonClassname}
      >
        <span style={textStyle}>
          {disabled && (
            <>
              <Spinner animation="border" variant="light" size="sm" />{' '}
            </>
          )}
          {text}
        </span>
      </Button>
    </div>
  );
};

export default ButtonComponent;
