import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import {
  Row,
  Col,
  Alert,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  FormGroup,
  Label,
} from 'reactstrap';
import { useAppSelector } from 'app/config/store';
import './setting.scss';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Footer from 'app/shared/layout/footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronBack from 'app/component/icon/chevron-back';
import Toggle from 'react-toggle';

export const Setting = props => {
  const account = useAppSelector(state => state.authentication.account);
  const navigate = useNavigate();
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        isAdmin={isAdmin}
        currentLocale={currentLocale}
        ribbonEnv={ribbonEnv}
        isInProduction={isInProduction}
        isOpenAPIEnabled={isOpenAPIEnabled}
      />

      <div className="container mt-5 mb-5">
        <div
          style={{ display: 'flex', alignContent: 'center', alignItems: 'center', alignSelf: 'center', cursor: 'pointer' }}
          onClick={() => goBack()}
        >
          <ChevronBack />
          <h5 style={{ color: '#0A0A0A', fontWeight: 'normal', fontSize: 18, marginBottom: 0 }} className="mx-2">
            Setting
          </h5>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-3">
            <h5 style={{ fontSize: 16 }} className="mb-4">
              Portfolio Alert Settings
            </h5>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Gain</div>
                <div className="mx-4">
                  <Input type="select" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}>
                    <option>5 %</option>
                    <option>10 %</option>
                    <option>15 %</option>
                    <option>20 %</option>
                  </Input>
                </div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Loss</div>
                <div className="mx-4">
                  <Input type="select" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}>
                    <option>5 %</option>
                    <option>10 %</option>
                    <option>15 %</option>
                    <option>20 %</option>
                  </Input>
                </div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>

            <div className="mt-4">
              <div className="my-2">
                <Input type="checkbox" name="select" placeholder="text" className="mb-0" style={{ marginBottom: 0 }}></Input>
                <span className="mx-2">Risk Tolerance Notification</span>
              </div>
              <div style={{ color: '#404040', fontWeight: 'normal', width: '80%', fontSize: 14 }} className="mt-2">
                Turning this on will let you know if your Risk Profile and your Portfolio Composition is matched or not.
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-3">
            <h5 style={{ fontSize: 16 }} className="mb-4">
              News Notification
            </h5>
            <p style={{ color: '#9E9E9E', fontSize: 14, fontWeight: 'normal' }}>
              You will receive notification every time there is an update!
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Stock News</div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Fund News</div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Bond News</div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Macro News</div>
              </div>
              <div>
                <Toggle icons={false} defaultChecked={''} aria-label="No label tag" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Setting;
